import React from 'react'
import { Modal, Button, Message } from 'semantic-ui-react';

interface Props {
    header: string;
    message: string;
    show: boolean;
    closeModal: () => void;
}

function FinancialValidation({ header, message, show, closeModal }: Props) {

    return (
        <Modal centered={false} open={show} closeOnEscape={false} closeOnDimmerClick={false} size="small">
            <Modal.Content>
                <Message color='yellow' icon='warning sign'
                    header={header}
                    content={message} />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => closeModal()}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default FinancialValidation