export interface Hurdle {
  id: number;
  investmentShareClassificationId: number;
  name: string;
  initialPercentage: number;
  finalPercentage: number;
  limitPercentage: number | null;
  preferredReturnType: number;
  catchUpOnPreferredReturns: number | null;
  honorOnlyCapitalEvents: number | null;
  dayCountConvention: number;
  finalHurdle: number | null;
  gpShareClassificationId: number | null;
}

export class Hurdle implements Hurdle {
  constructor(init?: Hurdle) {
    Object.assign(this, init);
  }
}