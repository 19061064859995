import { DropdownItemProps } from "semantic-ui-react";
import { PageParams, SortOrder } from "../app/models/common/PagedResult";
import { KeyMetricVisualizationSignEnum } from "../app/models/Investment/KeyMetric";

const etherscanUrl = process.env.REACT_APP_CRYPTO_NETWORK_SCAN as string;
const fullSizeWidth = 'min-width:768px';
export const maxFiles = parseInt(process.env.REACT_APP_UPLOAD_TOTAL_FILES ?? "25");
export const maxSize = parseInt(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE_IN_KB ?? "10240") * 1024; //10 mb
export const minSize = parseInt(process.env.REACT_APP_UPLOAD_MIN_FILE_SIZE_IN_BYTES ?? "100"); //about 100 bytes

export const getFullSizeWidth = () => fullSizeWidth;

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export const formatCurrency = (number: number | undefined, removePrefix: boolean = false, decimalDigits: number = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimalDigits
  });

  const formattedAmount = formatter.format(number!);

  if (removePrefix) {
    return formattedAmount.replace(/^./, '');
  }

  return formattedAmount;
}

export const getEtherscanUrl = () => etherscanUrl;

export const formatFullDate = (date: any) => {
  return new Date(date).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
}

export const getMonthName = (date: any) => {
  return new Date(date).toLocaleString('en-US', { month: 'long' });
};

export const getMonthAndDay = (date: any) => {
  return new Date(date).toLocaleString('en-US', { month: '2-digit', day: '2-digit' });
};

export const maskPhone = (phoneNumber: string) => {
  if (!phoneNumber) return phoneNumber;
  return `+# ###-###-${phoneNumber.slice(-4)}`;
}

export const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export const handleGridSort = (params: any, clickedColumn: string) => {
  const sort = { ...params };
  let newOrder: SortOrder = params.sortOrder === 'ascending' ? 'descending' : 'ascending';
  if (params.sortIndex !== clickedColumn) {
    newOrder = 'ascending';
  }
  sort.sortIndex = clickedColumn;
  sort.sortOrder = newOrder;

  return sort;
}

export const handleGridSearch = (params: any, value: string) => ({ ...params, search: value });

export const handleGridNavigation = (params: any, updatedParams: PageParams) => ({ ...params, pageIndex: updatedParams.pageIndex, pageSize: updatedParams.pageSize });

export const getFloatValue = (value: any) => {
  if (!value) return 0;
  return parseFloat(value.toString().replace(/,/gi, ''));
}

export const formatDate = (value: any) => {
  if (!value || value === undefined || value === "") {
    return "";
  }

  return new Date(value).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
}

export const formatDateFormal = (value: any) => {
  if (!value || value === undefined || value === "") {
    return "";
  }

  return new Date(value).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: '2-digit' });
}

export const formatMonthYearDate = (value: any) => {
  if (!value || value === undefined || value === "") {
    return "";
  }

  const formattedDate = new Date(value).toLocaleString('en-US', { month: '2-digit', year: 'numeric' });

  return formattedDate === '01/1' ? '' : formattedDate;
}

export const formatCryptoAddress = (value: string) => {
  if (!value) return "";
  return value.substring(0, 8) + '...' + value.substring(value.length - 6);
}

export const booleanOptions: DropdownItemProps[] = [
  { key: "False", value: 0, text: "No" },
  { key: "True", value: 1, text: "Yes" },
];

export const getFileSize = (fileSizeInBytes: number) => {
  if (fileSizeInBytes > 1024) {
    if (fileSizeInBytes > 1024 * 1024) {
      return (fileSizeInBytes / 1024 / 1024).toFixed(2) + " MB";
    }
    else {
      return (fileSizeInBytes / 1024).toFixed(2) + " KB";
    }
  }
  return fileSizeInBytes + " Bytes";
}

export const getFileIcon = (fileExtension: string) => {
  var icon = "";
  switch (fileExtension) {
    case ".html":
    case ".xml":
    case ".txt":
    case ".tsv":
    case ".csv":
    case ".css":
      icon = "file text outline";
      break;
    case ".pdf":
      icon = "file pdf outline";
      break;
    case ".zip":
      icon = "file zip outline";
      break;
    case ".doc":
    case ".docx":
      icon = "file word outline";
      break;
    case ".xls":
    case ".xlsx":
      icon = "file excel outline";
      break;
    case ".gif":
    case ".jpg":
    case ".png":
    case ".tif":
    case ".bmp":
      icon = "file image outline";
      break;
    default:
      icon = "file alternate outline";
  }

  return icon;
}

export const enumToKeyValueList = (enumObject: { [k: number]: string; }) => {
  return Object.entries(enumObject)
    .filter(([key]) => parseInt(key) > 0)
    .map(([key, value]) => ({
      key: key,
      value: parseInt(key),
      text: value
    }));
};

export function getEnumValue(myEnum: any, enumValue: string | number) {
  const matchingKey = Object.keys(myEnum).find(key => {
    const value = myEnum[key];
    return value === enumValue || key === enumValue || String(value).toLowerCase() === String(enumValue).toLowerCase();
  });

  return matchingKey ? parseInt(matchingKey, 10) : 0;
}

export function getEnumText(myEnum: any, enumValue: string | number) {
  let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue || x === enumValue || myEnum[x].toString().toLowerCase() === enumValue.toString().toLowerCase());
  return keys.length > 0 ? keys[0] : "";
}

export function getLastSegmentOfRoute() {
  const pathname = window.location.pathname;
  const trimmedPathname = pathname.replace(/\/+$/, '');
  const pathSegments = trimmedPathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];
  return lastSegment?.toLocaleLowerCase();
}

export const getRandomNumber = () => {
  return new Date().getTime();
}

export const yearsOptions: DropdownItemProps[] = [
  { key: "0", value: "12", text: "12" },
  { key: "1", value: "24", text: "24" },
  { key: "2", value: "60", text: "60" },
  { key: "3", value: "120", text: "120" },
  { key: "4", value: "360", text: "360" },
];

export const check2Step = () => {
  const cachedData = getTwoStepCacheData();

  if (cachedData) {
    return cachedData;
  }

  window.location.href = "/verification";
}

export const downloadFile = (response: any) => {
  const href = window.URL.createObjectURL(response.data);
  const anchorElement = document.createElement('a');

  anchorElement.href = href;

  const contentDisposition = response.headers['content-disposition'];
  let fileName = getFilenameFromContentDisposition(contentDisposition);

  anchorElement.download = fileName;
  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
}

const getFilenameFromContentDisposition = (contentDisposition: any) => {
  const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
  if (matches && matches.length > 0) {
    return matches[1].replace(/['"]/g, '');
  }
  return null;
};

export const enum CacheNameEnum {
  TwoStep = 'cacheTwoStep',
  Entity = 'entity',
  SideBar = 'sidebarOn',
  IconsOnly = 'iconsOnly',
  Countries = 'cachedCountries',
  States = 'cachedStates',
  ResidencyStatus = 'cachedResidencyStatus',
  LoanEnums = 'cachedLoanEnums',
  InvestmentEnums = 'cachedInvestmentEnums',
  DocumentTypes = 'cachedDocumentTypes',
  OfferingExpiryDurations = 'cachedOfferingExpiryDurations',
  EscrowTypes = 'cachedEscrowTypes',
  EntityListAddressBalances = 'cacheEntityListAddressBalances',
  UserEntities = 'cacheUserEntities',
  UserInfo = "cachedUserInfo",
  FeeEnums = 'cachedFeeEnums',
  GridsCache = 'cachedGridsSelection',
  LenderLoanFilterCache = 'cachedLenderLoanFilterSelection',
  BorrowerLoanFilterCache = 'cachedBorrowerLoanFilterSelection',
  RedirectUrlCache = 'cachedRedirectUrl',
}

export const getTwoStepCacheData = () => {
  const cachedData = localStorage.getItem(CacheNameEnum.TwoStep);

  if (cachedData) {
    const parsedData = JSON.parse(cachedData) as { timestamp: number; data: boolean[] };
    const currentTime = new Date().getTime();
    const oneDay = 24 * 60 * 60 * 1000;

    if (parsedData.timestamp && currentTime - parsedData.timestamp < oneDay) {
      return parsedData.data;
    }
  }

  return null;
};

export const clearCache = () => {
  sessionStorage.removeItem(CacheNameEnum.Entity);
  sessionStorage.removeItem(CacheNameEnum.EntityListAddressBalances);
  sessionStorage.removeItem(CacheNameEnum.UserEntities);
  sessionStorage.removeItem(CacheNameEnum.UserInfo);
  localStorage.removeItem(CacheNameEnum.TwoStep);
}

export const getGridsRecordsPerPage = () => {
  const cachedData = localStorage.getItem(CacheNameEnum.GridsCache);

  if (cachedData) {
    const parsedData = JSON.parse(cachedData);
    return parsedData.recordsPerPage || 5;
  }

  return 5;
};

export const setGridsRecordsPerPage = (recordsPerPage: number) => {
  const cachedData = localStorage.getItem(CacheNameEnum.GridsCache);
  const gridsCache = cachedData ? JSON.parse(cachedData) : {};
  gridsCache.recordsPerPage = recordsPerPage;
  localStorage.setItem(CacheNameEnum.GridsCache, JSON.stringify(gridsCache));
};

export const createGridInitialState = (specificParams: any) => {
  return {
    ...specificParams,
    pageIndex: 0,
    pageSize: getGridsRecordsPerPage()
  };
};

export const getLoanFilter = (cache: CacheNameEnum) => {
  const cachedData = localStorage.getItem(cache);

  if (cachedData) {
    const parsedData = JSON.parse(cachedData);
    return parsedData.status || 2;
  }

  return 2;
};

export const setLoanFilter = (status: number, cache: CacheNameEnum) => {
  const cachedData = localStorage.getItem(cache);
  const loanFilterCache = cachedData ? JSON.parse(cachedData) : {};
  loanFilterCache.status = status;
  localStorage.setItem(cache, JSON.stringify(loanFilterCache));
};

export const getRedirectUrl = () => {
  return localStorage.getItem(CacheNameEnum.RedirectUrlCache);
}

export const clearRedirectUrl = () => {
  localStorage.removeItem(CacheNameEnum.RedirectUrlCache);
};

export const setRedirectUrl = (redirectUrl: string) => {
  localStorage.setItem(CacheNameEnum.RedirectUrlCache, redirectUrl);
}

export const getPreferredReturnTypeAcronym = (type: number) => {
  return type === 1 ? "CoC" : "ROI";
}

export const getVisualizationSignIcon = (visualizationSign: KeyMetricVisualizationSignEnum) => {
  return getEnumValue(KeyMetricVisualizationSignEnum, visualizationSign) === KeyMetricVisualizationSignEnum.Factor ? 'x' : '%';
}

export const formatStatisticNumber = (value: number) => {
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(3).replace(/\.?0+$/, '')}M`;
  } else if (value >= 1000) {
    return `$${(value / 1000).toFixed(3).replace(/\.?0+$/, '')}k`;
  } else {
    return `$${value.toFixed(2).replace(/\.?0+$/, '')}`;
  }
}
