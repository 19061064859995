import { List, ListContent, ListDescription, ListHeader, ListIcon, ListItem, Segment } from "semantic-ui-react"
import { SponsorshipTeamView } from "../../app/models/GpLanding/sponsorshipTeamView"
import { useStore } from "../../app/stores/store";
import { useState, useEffect } from "react";
import React from "react";

interface Params {
    investmentId: number,
    enableHeader: boolean
}
function SponsorshipTeamPanel({ investmentId, enableHeader }: Params) {
    const { investmentInsightsStore } = useStore();
    const [sponsors, setSponsors] = useState<SponsorshipTeamView[] | null>(null)
    const [loading, setLoading] = useState(true);
    const iconSize = 'small'

    useEffect(() => {
        setLoading(true);
        investmentInsightsStore.getSponsorshipTeam(investmentId)
            .then(r => {
                setSponsors(r);
            })
            .finally(() => setLoading(false));
    }, [investmentId, investmentInsightsStore])

    return (
        <Segment loading={loading} clearing className='classTable' color='blue'>
            {sponsors !== null && sponsors.length > 0 ?
                <List divided verticalAlign="top" style={{ padding: 10 }} >
                    {sponsors.map((sponsor, i) => {
                        return (
                            <ListItem key={i}>
                                <ListIcon name='user outline' size={iconSize} verticalAlign='middle' />
                                <ListContent>
                                    <ListHeader><span style={{ fontSize: 'larger' }}>{sponsor.firstName} {sponsor.lastName}</span></ListHeader>
                                    <ListDescription></ListDescription>
                                </ListContent>
                            </ListItem>)
                    })}

                </List>
                :
                <List divided verticalAlign="top" style={{ padding: 10 }} >
                    <ListItem>
                        <ListIcon name='user outline' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Unknown</span></ListHeader>
                            <ListDescription></ListDescription>
                        </ListContent>
                    </ListItem>
                </List>
            }
        </Segment>
    )
}

export default SponsorshipTeamPanel