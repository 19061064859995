export interface InvestmentInterestGrid {
    id: number;
    entityName: string;
    entityTypeDescription: string;
    shareClass: string;
    amount: number;
    accreditationDescription: string;
    fundingMethodDescription: string;
    email: string;
    cellPhone: string;
    approved: boolean;
}

export class InvestmentInterestGrid implements InvestmentInterestGrid {
    constructor(init?: InvestmentInterestGrid) {
        Object.assign(this, init);
    }
}