import { Header, Segment, Grid, GridColumn, GridRow, HeaderContent, HeaderSubheader, Label, List, ListContent, ListDescription, ListHeader, ListIcon, ListItem, Icon } from "semantic-ui-react"
import { InvestmentSummaryView } from "../app/models/GpLanding/InvestmentSummaryView";
import InvestmentImages from "../features/investment/Details/InvestmentImages";
import { formatCurrency, formatDate } from "./utils";
import { Link } from "react-router-dom";

interface Params {
    investment: InvestmentSummaryView;
    isConnected: boolean;
}

function InvestmentSummaryCard({ investment, isConnected }: Params) {
    const iconSize = 'large';

    return (
        <Segment clearing>
            <Grid className='classTable'>
                <GridRow columns={2}>
                    <GridColumn>
                        <Header as='h2' color='blue'>
                            <HeaderContent>
                                {isConnected
                                    ?
                                    <Link to={`/investor/investment-insights/${investment.investmentId}`}>{investment.name}</Link>
                                    :
                                    <>
                                        {investment.name}
                                    </>
                                }
                            </HeaderContent>
                            <HeaderSubheader><Link to={`/gpLanding/${investment.sponsorEntityId}`}>{investment.sponsorName}</Link></HeaderSubheader>
                        </Header>
                    </GridColumn>
                    <GridColumn textAlign="right">
                        <Label color='green'>{investment.status}</Label>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <InvestmentImages headerText={""} investmentId={investment.investmentId} entityId={investment.sponsorEntityId} enableInvestmentLink={isConnected} showThumbnails={false}></InvestmentImages>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <div>
                            <Icon name='map marker'></Icon>
                            {investment.city !== '' ?
                                <>
                                    {investment.city}, {investment.stateCode}
                                </>
                                :
                                <>
                                    {investment.stateCode}
                                </>
                            }
                        </div>
                        <Grid columns={'equal'}>
                            <GridColumn>
                                <List celled relaxed verticalAlign="top"  >
                                    <ListItem>
                                        <ListIcon name='building' size={iconSize} verticalAlign='middle' />
                                        <ListContent>
                                            <ListHeader><span style={{ fontSize: 'larger' }}>Property Type</span></ListHeader>
                                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{investment.propertyType !== null ? investment.propertyType : 'Unknown'}</span></ListDescription>
                                        </ListContent>
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon name='pie graph' size={iconSize} verticalAlign='middle' />
                                        <ListContent>
                                            <ListHeader><span style={{ fontSize: 'larger' }}>Investor Eligibility</span></ListHeader>
                                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >[TBD]</span></ListDescription>
                                        </ListContent>
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon name='area graph' size={iconSize} verticalAlign='middle' />
                                        <ListContent>
                                            <ListHeader><span style={{ fontSize: 'larger' }}>Total Raise Amount</span></ListHeader>
                                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{formatCurrency(investment.totalRaiseAmount)}</span></ListDescription>
                                        </ListContent>
                                    </ListItem>
                                </List>
                            </GridColumn>
                            <GridColumn>
                                <List celled relaxed verticalAlign="top">
                                    <ListItem>
                                        <ListIcon name='industry' size={iconSize} verticalAlign='middle' />
                                        <ListContent>
                                            <ListHeader><span style={{ fontSize: 'larger' }}>Deal Type</span></ListHeader>
                                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{investment.dealType}</span></ListDescription>
                                        </ListContent>
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon name='money' size={iconSize} verticalAlign='middle' />
                                        <ListContent>
                                            <ListHeader><span style={{ fontSize: 'larger' }}>Minimum Investment</span></ListHeader>
                                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{formatCurrency(investment.minimumInvestment)}</span></ListDescription>
                                        </ListContent>
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon name='certificate' size={iconSize} verticalAlign='middle' />
                                        <ListContent>
                                            <ListHeader><span style={{ fontSize: 'larger' }}>SEC Type</span></ListHeader>
                                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{investment.secType}</span></ListDescription>
                                        </ListContent>
                                    </ListItem>
                                </List>
                            </GridColumn>
                            <GridRow>
                                <GridColumn textAlign="right">
                                    Close date: {formatDate(investment.closeDate)}
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Segment>
    )
}
export default InvestmentSummaryCard