import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { Entity } from '../app/models/registration/entity';
import { useStore } from '../app/stores/store';
import { FormField, Icon, Input } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop } from '@fortawesome/free-solid-svg-icons';

interface Props {
    sponsorEntityId: number;
    showAsTextBox?: boolean;
    isTextboxClickable?: boolean;
    textBoxTitle?: string;
}

function GpMarketingPageLink({ sponsorEntityId, showAsTextBox = false, textBoxTitle = "", isTextboxClickable = true }: Props) {
    const [sponsorEntity, setSponsorEntity] = useState<Entity | null>(null);
    const { entityStore } = useStore();
    useEffect(() => {
        if (sponsorEntityId > 0) {
            entityStore.getEntity(sponsorEntityId)
                .then((r) => {
                    setSponsorEntity(r);
                })
        }
    }, [entityStore, sponsorEntityId]);

    if (showAsTextBox) {
        return <>
            {sponsorEntity !== null &&
                <FormField>
                    <label>Sponsor</label>
                    {isTextboxClickable ?
                        <Input as={NavLink} to={`/GPLanding/${sponsorEntity?.id}`}
                            placeholder={textBoxTitle} value={sponsorEntity?.name} readOnly className='gpLandingUrl'
                            icon={<Icon circular >
                                <FontAwesomeIcon icon={faShop} color='#4183c4' />
                            </Icon>
                            }
                        />
                        :
                        <Input placeholder={textBoxTitle} value={sponsorEntity?.name} readOnly />
                    }
                </FormField>
            }
        </>
    }

    return (
        <>
            {sponsorEntity !== null &&
                <Link to={`/Gplanding/${sponsorEntity?.id}`}><FontAwesomeIcon icon={faShop} color='#4183c4' /> {sponsorEntity?.name}</Link>
            }
        </>
    )
}

export default GpMarketingPageLink