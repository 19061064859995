import React, { useContext, useEffect, useState } from 'react'
import MyInvestmentsGrid from './MyInvestmentsGrid'
import SponsorInvestmentsGrid from './SponsorInvestmentsGrid'
import EntityContext from '../../app/context/entityContext';
import { useStore } from '../../app/stores/store';
import { Button, Header, Segment } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import InvestmentsGrid from './Manage/InvestmentsGrid';

function InvestmentDashboard() {
  const { entity } = useContext(EntityContext);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { investmentStore } = useStore();
  useEffect(() => {
    investmentStore.checkSponsorEntity(entity.id)
      .then(r => {
        setTotalRecords(r.totalRecords);
      })
      .finally(() => setLoading(false));
  }, [investmentStore, entity.id, setLoading]);

  return (
    <>
      {!loading && totalRecords > 0 && <>
        <SponsorInvestmentsGrid />
        <InvestmentsGrid />
      </>
      }
      {!loading && totalRecords === 0 &&
        <>
          <MyInvestmentsGrid />
        </>
      }
      <br />
      <Segment basic textAlign='center' loading={loading}>
        <Header>
          Interest in bringing on an investment to CapBlock?
        </Header>
        <Button onClick={() => navigate('/investment/create')} content='Create Investment' primary />
      </Segment >
    </>
  )
}

export default InvestmentDashboard 