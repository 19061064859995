import { Header, Icon, List, ListContent, ListDescription, ListHeader, ListIcon, ListItem, Segment } from "semantic-ui-react"
import { SponsorshipTeamView } from "../../app/models/GpLanding/sponsorshipTeamView"
import { useStore } from "../../app/stores/store";
import { useState, useEffect } from "react";

interface Params {
    entityId: number,
    enableHeader: boolean
}
function GPSponsorshipTeamCard({ entityId, enableHeader }: Params) {
    const { entityProfileStore } = useStore();
    const [sponsors, setSponsors] = useState([new SponsorshipTeamView()]);
    const iconSize = 'small';

    useEffect(() => {
        entityProfileStore.getGpSponsorshipTeam(entityId)
            .then(r => setSponsors(r))
    }, [entityId, entityProfileStore])

    return (
        <Segment clearing className='classTable'>
            {enableHeader &&
                <Header as='h3' image style={{ marginBottom: 0, padding:10 }}>
                    <Icon name='users'></Icon>
                    <Header.Content>Sponsorship Team</Header.Content>
                </Header>

            }
            <List divided verticalAlign="top" style={{ padding: 10 }} >
                {sponsors.map((sponsor, i) => {
                    return (
                        <ListItem key={i}>
                            <ListIcon name='user outline' size={iconSize} verticalAlign='middle' />
                            <ListContent>
                                <ListHeader><span style={{ fontSize: 'larger' }}>{sponsor.firstName} {sponsor.lastName}</span></ListHeader>
                                <ListDescription></ListDescription>
                            </ListContent>
                        </ListItem>)
                })}

            </List>
        </Segment>
    )
}

export default GPSponsorshipTeamCard