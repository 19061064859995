import React, { useEffect, useState } from 'react'
import { Investment } from '../../../app/models/Investment/Investment';
import { Form, Grid, GridColumn, GridRow, Header, Icon, Segment, TabPane } from 'semantic-ui-react';
import { Formik } from 'formik';
import TextInput from '../../../app/common/form/TextInput';
import SelectInput from '../../../app/common/form/SelectInput';
import { useStore } from '../../../app/stores/store';
import InvestmentImages from './InvestmentImages';
import { booleanOptions } from '../../../shared/utils';
import { Link } from 'react-router-dom';
import GpMarketingPageLink from '../../../shared/GpMarketingPageLink';
import { faShop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    investment: Investment;
}

function InvestmentDetail({ investment }: Props) {

    const emptyOption = { key: '' };
    const { commonStore } = useStore();
    const [investmentTypeOptions, setInvestmentTypeOptions] = useState([emptyOption]);
    const [investmentStatusOptions, setInvestmentStatusOptions] = useState([emptyOption]);
    const [secTypeOptions, setSecTypeOptions] = useState([emptyOption]);

    useEffect(() => {
        commonStore.getInvestmentEnums().then(r => {
            setInvestmentTypeOptions(r.investmentType);
            setInvestmentStatusOptions(r.investmentStatus);
            setSecTypeOptions(r.secType);
        })
    }, [commonStore, setInvestmentTypeOptions, setInvestmentStatusOptions])

    return (
        <TabPane>
            <Grid columns={2}>
                <GridRow>
                    <GridColumn>
                        <Header as='h3'>
                            About {investment.name}
                        </Header>
                    </GridColumn>
                    <GridColumn textAlign='right'>
                        <Link to={`/investor/investment-insights/${investment.id}`}><FontAwesomeIcon icon={faShop} color='#4183c4' />  View Investment</Link>
                    </GridColumn>
                </GridRow>
            </Grid>

            <Segment>
                {investment.description}
            </Segment>

            <InvestmentImages headerText={'Photo Gallery'} investmentId={investment.id} entityId={investment.sponsorEntityId} enableInvestmentLink={false} showThumbnails={true} />

            <Header as='h3'>
                Investment Details
            </Header>
            <Segment>
                <Formik
                    enableReinitialize
                    initialValues={investment}
                    onSubmit={() => { }}>
                    {({ handleSubmit, values }) => (
                        <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            <Form.Group widths='equal'>
                                <GpMarketingPageLink sponsorEntityId={values.sponsorEntityId} showAsTextBox textBoxTitle='Owning Entity' />
                                <TextInput placeholder='Asset Manager' name='assetManager' maxLength={512} readOnly />
                                <TextInput placeholder='Creation Date' name='created' maxLength={10} readOnly />
                                <TextInput placeholder='Date Active' name='dateActive' maxLength={10} readOnly />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <SelectInput options={investmentTypeOptions} placeholder='Type' name='investmentType' disabled />
                                <TextInput placeholder='Location' name='stateCode' maxLength={512} readOnly />
                                <SelectInput options={booleanOptions} placeholder='Is Security' name='isSecurity' disabled />
                                {!!investment.isSecurity && <SelectInput options={secTypeOptions} placeholder='SEC Type' name='secType' disabled />}
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <TextInput placeholder='Return(%)' name='returnPercentage' maxLength={512} readOnly icon={<Icon name='percent' />} />
                                <TextInput placeholder='CoC(%)' name='coCPercentage' maxLength={10} readOnly icon={<Icon name='percent' />} />
                                <SelectInput options={investmentStatusOptions} placeholder='Status' name='investmentStatus' disabled />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Segment>
        </TabPane>
    )
}

export default InvestmentDetail