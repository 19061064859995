import React, { useContext, useEffect, useState } from 'react'
import { Grid, Header, Icon, Segment, Table } from 'semantic-ui-react'
import { createGridInitialState, formatCurrency, formatDate, getFullSizeWidth, handleGridNavigation, handleGridSort } from '../../../shared/utils'
import { useStore } from '../../../app/stores/store';
import EntityContext from '../../../app/context/entityContext';
import { useMediaQuery } from 'react-responsive';
import EmptyGridMessage from '../../../shared/EmptyGridMessage';
import GridFooter from '../../../shared/GridFooter';
import { PageParams } from '../../../app/models/common/PagedResult';
import { InvestmentQueryFilter } from '../../../app/models/common/InvestmentQueryFilter';
import { Investment } from '../../../app/models/Investment/Investment';
import { Distribution } from '../../../app/models/Investment/Distribution';
import DistributionDetail from '../Manage/Distributions/DistributionDetail';


interface Props {
    investment: Investment;
}

function ShareholderDistributionGrid({ investment }: Props) {
    const [loading, setLoading] = useState(true);
    const { investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [distributions, setDistributions] = useState([new Distribution()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const [showDistribution, setShowDistribution] = useState(false);
    const [distribution, setDistribution] = useState(0);

    const initialState: InvestmentQueryFilter = createGridInitialState({
        entityId: entity.id,
        investmentId: investment.id,
        showAll: false,
        sortIndex: 'DistributionDate',
        sortOrder: 'descending'
    });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        investmentStore.getShareholderDistributions(params).then(r => {
            setTotalRecords(r.totalRecords);
            setDistributions(r.data);
            setLoading(false);
        });
    }, [investmentStore, params, setLoading]);


    const handleSort = (clickedColumn: string) => {
        if (distributions.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const viewDistributionDetail = (id: number) => {
        setDistribution(id);
        setShowDistribution(true);
    }

    const handleBack = () => {
        setDistribution(0);
        setShowDistribution(false);
    }

    return (
        <Segment clearing loading={loading}>
            {showDistribution ?
                <DistributionDetail investmentId={investment.id} distributionId={distribution} onBack={handleBack} />
                :
                <>
                    <Header>My Distributions</Header>
                    <Table singleLine sortable selectable>
                        {showFullSize &&
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell onClick={() => handleSort("DistributionDate")} sorted={params.sortIndex === "DistributionDate" && distributions.length > 0 ? params.sortOrder : undefined}>Date</Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => handleSort("DistributionType")} sorted={params.sortIndex === "DistributionType" && distributions.length > 0 ? params.sortOrder : undefined}>Type</Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => handleSort("Comment")} sorted={params.sortIndex === "Comment" ? params.sortOrder : undefined}>Description</Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => handleSort("DistributionAmount")} sorted={params.sortIndex === "DistributionAmount" ? params.sortOrder : undefined}>Total Distribution</Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => handleSort("ShareholderAmount")} sorted={params.sortIndex === "ShareholderAmount" ? params.sortOrder : undefined}>My Distribution</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        }

                        <Table.Body>
                            {!loading && distributions.map((distribution, i) => {
                                if (showFullSize) {
                                    return <Table.Row key={i} style={{ cursor: 'pointer' }} onClick={() => viewDistributionDetail(distribution.id)}>
                                        <Table.Cell textAlign='center'>{formatDate(distribution.distributionDate)}</Table.Cell>
                                        <Table.Cell>{distribution.distributionTypeDescription}</Table.Cell>
                                        <Table.Cell>{distribution.comment}</Table.Cell>
                                        <Table.Cell textAlign='right'>{formatCurrency(distribution.distributionAmount)}</Table.Cell>
                                        <Table.Cell textAlign='right'>{formatCurrency(distribution.shareholderAmount)}</Table.Cell>
                                    </Table.Row>
                                }
                                else {
                                    return <Table.Row key={i}>
                                        <Table.Cell>
                                            {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                            <Grid>
                                                <Grid.Row style={{ cursor: 'pointer' }}>
                                                    <Grid.Column width={6}>
                                                        <label onClick={() => handleSort("DistributionDate")}>Date:</label><br />
                                                        <label onClick={() => handleSort("ShareClass")}>Class:</label><br />
                                                        <label onClick={() => handleSort("DistributionType")}>Type:</label><br />
                                                        <label onClick={() => handleSort("Comment")}>Description:</label><br />
                                                        <label onClick={() => handleSort("DistributionAmount")}>Total:</label><br />
                                                        <label onClick={() => handleSort("Amount")}>My Total:</label><br />
                                                    </Grid.Column>
                                                    <Grid.Column style={{ fontWeight: 'normal' }} >
                                                        <div style={{ width: '45vw' }} onClick={() => viewDistributionDetail(distribution.id)}>
                                                            {formatDate(distribution.distributionDate)}<br />
                                                            {distribution.distributionTypeDescription}<br />
                                                            {distribution.comment}<br />
                                                            {formatCurrency(distribution.distributionAmount)}<br />
                                                            {formatCurrency(distribution.shareholderAmount)}<br />
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Table.Cell>
                                    </Table.Row>
                                }
                            })}
                            {!loading && distributions.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 5 : 1} message='You do not hold any distribution' />}
                        </Table.Body>
                        {!loading && distributions.length > 0 &&
                            <>
                                <GridFooter colSpan={showFullSize ? 5 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />

                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan={4} textAlign='right'>
                                            <b>Total {formatCurrency(distributions[0].totalDistributionAmount)}</b>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign='right'>
                                            <b>Total {formatCurrency(distributions[0].totalShareholderAmount)}</b>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </>
                        }
                    </Table>
                </>
            }
        </Segment>
    )
}

export default ShareholderDistributionGrid