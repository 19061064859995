import { faFacebook, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFingerprint, faGlobe, faLocationDot, faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Button, Header, Icon, Image, Label, List, ListContent, ListItem, Segment, Table, TableBody } from 'semantic-ui-react';
import { EntityProfileView } from '../../app/models/GpLanding/entityProfileView';
import { useEffect, useState } from 'react';
import { useStore } from '../../app/stores/store';
import { setRedirectUrl } from '../../shared/utils';
import GpConnectOptInModal from './gpConnectOptInModal';
import { toast } from "react-toastify";
import React from 'react';
import { Entity } from '../../app/models/registration/entity';

interface Params {
    sponsorEntityId: number;
    entityId: number;
    entityType: number;
    isAuthenticated: boolean;
    onConnectedChange: (isConnected: boolean) => void;
}

function GPProfileInfoCard({ sponsorEntityId, entityId, entityType, isAuthenticated, onConnectedChange }: Params) {
    const infoStyle = {
        margin: 10
    }
    const navigate = useNavigate();
    const { entityProfileStore } = useStore();
    const [entityProfileView, setEntityProfileView] = useState(new EntityProfileView());
    const [loading, setLoading] = useState(true);
    const [showOptInModal, setShowOptInModal] = useState(false);
    const { sponsorContactStore } = useStore();
    const [isConnectedToSponsor, setIsConnectedToSponsor] = useState(false);
    const [sponsorEntity, setSponsorEntity] = useState(new Entity());
    const { entityStore } = useStore();
    useEffect(() => {
        entityProfileStore.getEntityProfileView(sponsorEntityId)
            .then(r => {
                setEntityProfileView(r);
            })
            .then(() => {
                if (isAuthenticated) {
                    sponsorContactStore.isEntityConnectedToSponsor(sponsorEntityId, entityId)
                        .then((c) => {
                            setIsConnectedToSponsor(c);
                        })
                }
            })
            .finally(() => setLoading(false))
    }, [sponsorEntityId, entityProfileStore, setEntityProfileView, setLoading, sponsorContactStore, isAuthenticated, entityId, onConnectedChange]);

    useEffect(() => {
        entityStore.getEntity(sponsorEntityId)
            .then((r) => {
                setSponsorEntity(r);
            })
    }, [entityStore, sponsorEntityId])
    const navigateToRegister = (() => {
        setRedirectUrl(window.location.href);
        navigate(`/register`);
    });

    const createConnectedEntity = (() => {
        sponsorContactStore.createSponsorConnection(sponsorEntityId, entityId, entityType)
            .then((r) => {
                entityProfileStore.getEntityProfileView(sponsorEntityId)
                    .then(r => {
                        setEntityProfileView(r);
                        setIsConnectedToSponsor(true);
                        onConnectedChange(true);
                        toast.success("You have successfully connected with this company", { theme: "colored" });
                    })
            })
            .catch((r) => {
                toast.error(r, { theme: "colored" });
            })
    });

    return (
        <Segment clearing className='classTable' loading={loading}>
            {showOptInModal &&
                <GpConnectOptInModal
                    sponsorName={entityProfileView.name}
                    supportEmail='support@capblock.com'
                    show={true} entityId={sponsorEntityId}
                    onAccept={function (): void {
                        setShowOptInModal(false);
                        createConnectedEntity();
                    }}
                    onCancel={function (): void {
                        setShowOptInModal(false);
                    }}
                />
            }
            <Table>
                <TableBody>
                    <Table.Row>
                        <Table.Cell>
                            {entityProfileView !== null &&
                                entityProfileView.profileImageUrl !== null &&
                                entityProfileView.profileImageUrl !== ''
                                ?
                                <Image src={entityProfileView.profileImageUrl} size={'large'} rounded />
                                :
                                <>
                                    <Segment placeholder>
                                        <Header icon>
                                            <Icon name='image outline' />
                                            Image not available
                                        </Header>
                                    </Segment>
                                </>
                            }
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Header as='h1'>{sponsorEntity.name}</Header></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><div>{entityProfileView.description}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign='center'>
                            {isAuthenticated ?
                                <>
                                    {isConnectedToSponsor ?
                                        <Label icon='user' color='blue' content='You are following this sponsor'></Label>
                                        :
                                        <Button primary type='button' icon='user plus' content='Connect' onClick={() => setShowOptInModal(true)} />

                                    }
                                </>
                                :
                                <>
                                    <Button primary type='button' icon='add user' content='Create an account to connect to this sponsor' onClick={() => navigateToRegister()}></Button>
                                </>
                            }
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <List>
                                {entityProfileView !== null &&
                                    entityProfileView.city !== null &&
                                    entityProfileView.city !== '' &&
                                    <ListItem>
                                        <ListContent>
                                            <FontAwesomeIcon size={'2xl'} icon={faLocationDot} style={infoStyle}></FontAwesomeIcon>{entityProfileView.city}, {entityProfileView.stateCode}
                                        </ListContent>
                                    </ListItem>
                                }
                                {entityProfileView != null &&
                                    <ListItem>
                                        <ListContent>
                                            <p>
                                                {entityProfileView.profileUrlLinkedIn != null &&
                                                    entityProfileView.profileUrlLinkedIn !== '' &&
                                                    <a href={entityProfileView.profileUrlLinkedIn}><FontAwesomeIcon size={'2xl'} style={infoStyle} icon={faLinkedinIn}></FontAwesomeIcon></a>

                                                }
                                                {entityProfileView.profileUrlFacebook != null &&
                                                    entityProfileView.profileUrlFacebook !== '' &&
                                                    <a href={entityProfileView.profileUrlFacebook}><FontAwesomeIcon size={'2xl'} style={infoStyle} icon={faFacebook}></FontAwesomeIcon></a>
                                                }
                                                {entityProfileView.profileUrlTwitterX !== null &&
                                                    entityProfileView.profileUrlTwitterX !== '' &&
                                                    <a href={entityProfileView.profileUrlTwitterX}><FontAwesomeIcon size={'2xl'} style={infoStyle} icon={faXTwitter}></FontAwesomeIcon></a>
                                                }
                                                {entityProfileView.profileUrlOther !== null &&
                                                    entityProfileView.profileUrlOther !== '' &&
                                                    <a href={entityProfileView.profileUrlOther}><FontAwesomeIcon size={'2xl'} style={infoStyle} icon={faGlobe}></FontAwesomeIcon></a>
                                                }
                                            </p>
                                        </ListContent>
                                    </ListItem>
                                }
                                <ListItem>
                                    <ListContent>
                                        {entityProfileView != null &&
                                            entityProfileView.identityVerified ?
                                            <p><FontAwesomeIcon size={'2xl'} color='green' icon={faFingerprint} style={infoStyle}></FontAwesomeIcon> Identity Verified </p>
                                            :
                                            <p><FontAwesomeIcon size={'2xl'} color='red' icon={faFingerprint} style={infoStyle}></FontAwesomeIcon> Identity Unverified </p>
                                        }
                                    </ListContent>
                                </ListItem>
                                {entityProfileView !== null &&
                                    entityProfileView.activeDeals > 0 &&
                                    <ListItem>
                                        <ListContent>
                                            <FontAwesomeIcon size={'2xl'} icon={faMoneyBillTrendUp} style={infoStyle}></FontAwesomeIcon> Active Deals
                                        </ListContent>
                                    </ListItem>
                                }
                            </List>

                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Label><Icon name='user'></Icon>{entityProfileView.contacts} contacts</Label>  <Label><Icon name='user'></Icon> {entityProfileView.followers} followers</Label> <Label><Icon name='user'></Icon> {entityProfileView.investors} investors</Label></Table.Cell>
                    </Table.Row>
                </TableBody>
            </Table>
        </Segment>
    )

}
export default GPProfileInfoCard