import { List, ListContent, ListDescription, ListHeader, ListIcon, ListItem, Placeholder, PlaceholderLine, PlaceholderParagraph, Segment } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { InvestmentDebtFinanceView } from "../../app/models/Investment/InvestmentDebtFinanceView";

interface Params {
    investmentId: number
}

function DebtFinancingPanelComponent({ investmentId }: Params) {

    const [loading, setLoading] = useState(true);
    const [debtFinance, setDebtFinance] = useState(new InvestmentDebtFinanceView());
    const { investmentInsightsStore } = useStore();
    const iconSize = 'large'


    useEffect(() => {
        setLoading(true);
        investmentInsightsStore.getDebtFinance(Number(investmentId))
            .then(r => setDebtFinance(r))
            .finally(() => setLoading(false));
    }, [investmentId, investmentInsightsStore])

    return (
        <Segment clearing loading={loading} raised color='red'>
            {loading &&
                <Placeholder>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                </Placeholder>
            }
            {!loading &&
                <List divided relaxed verticalAlign="top" style={{ padding: 10 }} >
                    <ListItem>
                        <ListIcon name='money bill alternate outline' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Financing Type</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{debtFinance.financingType}</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='pie graph' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Loan-to-Value</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{debtFinance.loanToValue}%</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='area graph' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Interest Rate</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{debtFinance.interestRate}%</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    <ListItem>
                        <ListIcon name='calendar alternate outline' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Loan Term</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{debtFinance.loanTerm} Years</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    {debtFinance.interestOnly && <ListItem>
                        <ListIcon name='calendar alternate outline' size={iconSize} verticalAlign='middle' />
                        <ListContent>
                            <ListHeader><span style={{ fontSize: 'larger' }}>Interest-only Period</span></ListHeader>
                            <ListDescription><span style={{ color: '#2185d0', fontSize: 'larger' }} >{debtFinance.interestOnlyPeriod} Years</span></ListDescription>
                        </ListContent>
                    </ListItem>
                    }
                </List>
            }
        </Segment>
    )
}

export default DebtFinancingPanelComponent