import { faShop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'semantic-ui-react';

export const MenuItems = [
  {
    key: 'dashboard',
    title: 'Home',
    path: '/dashboard',
    icon: <Icon name='home' size='large' />
  },
  {
    key: 'loans',
    title: 'Loans',
    path: '/loans',
    icon: <Icon name='money bill alternate outline' size='large' />
  },
  {
    key: 'investments',
    title: 'Investments',
    path: '/investments',
    icon: <Icon name='line graph' size='large' />
  },
  {
    key: 'marketplace',
    title: 'Market Place',
    path: '/marketplace',
    icon: <Icon size='large'>
      <FontAwesomeIcon icon={faShop} />
    </Icon>
  },
  {
    key: 'settings',
    title: 'Settings',
    path: '/settings',
    icon: <Icon name='setting' size='large' />
  }
];
