import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../../features/home/HomePage';
import NotFound from '../errors/NotFound';
import AuthProvider from './AuthProvider';
import ProtectedRoute from './ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import RegistrationForm from '../../features/registration/registrationForm';
import EntityForm from '../../features/registration/entityForm';
import EntityManagement from '../../features/entity/entityManagement';
import Settings from '../../features/settings/settings';
import EntityContext from '../context/entityContext';
import { Entity } from '../models/registration/entity';
import EntitySwitch from '../../features/entity/entitySwitch';
import Dashboard from '../../features/home/Dashboard';
import LoanDashboard from '../../features/loan/LoanDashboard';
import LoanForm from '../../features/loan/LoanForm';
import LoanViewForm from '../../features/loan/LoanViewForm';
import Logout from '../../shared/Logout';
import InvestmentDashboard from '../../features/investment/InvestmentDashboard';
import Investment from '../../features/investment/Investment';
import TwoStepLogin from '../../shared/TwoStepLogin';
import { CacheNameEnum } from '../../shared/utils';
import StripeAccountPannel from '../../features/settings/stripeAccountPannel';
import Details from '../../features/investment/Manage/Details/Details';
import InvestmentTab from '../../features/investment/Manage/InvestmentNavigation/InvestmentTab';
import GPLanding from '../../features/gplanding/gplanding';
import InvestmentInsights from '../../features/investor/investmentinsights';
import NavBarAuthenticationRequired from './NavBar/NavBarAuthenticationRequired';
import NavBarAuthenticationNotRequired from './NavBar/NavBarAuthenticationNotRequired';
import NavBarHybrid from './NavBar/NavBarHybrid';
import DiscoverInvestments from '../../features/marketplace/discoverInvestments';


function App() {
  const [entity, setEntity] = useState<Entity>(getInitialState());

  function getInitialState() {
    const entityContext = sessionStorage.getItem(CacheNameEnum.Entity)
    return entityContext ? JSON.parse(entityContext) : new Entity();
  }

  useEffect(() => {
    sessionStorage.setItem(CacheNameEnum.Entity, JSON.stringify(entity))
  }, [entity])

  return (
    <AuthProvider>
      <>
        <ToastContainer position='bottom-right' hideProgressBar autoClose={3000} closeOnClick />
        <EntityContext.Provider value={{ entity, setEntity }}>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/logout' element={<Logout />} />
            <Route path="/switch" element={<ProtectedRoute component={EntitySwitch} />} />
            <Route path="/verification" element={<ProtectedRoute component={TwoStepLogin} />} />

            <Route element={<NavBarHybrid />}>
              <Route path="/gplanding">
                <Route path=":id" element={<GPLanding />} />
              </Route>
            </Route>

            <Route element={<NavBarAuthenticationNotRequired />}>
              <Route path="/register" element={<ProtectedRoute component={RegistrationForm} />} />
            </Route>

            <Route element={<NavBarAuthenticationRequired />}>
              <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
              <Route path="/loans" element={<ProtectedRoute component={LoanDashboard} />} />
              <Route path="/loan">
                <Route index element={<ProtectedRoute component={LoanForm} />} />
                <Route path=":id" element={<ProtectedRoute component={LoanForm} />} />
              </Route>
              <Route path="/marketplace" element={<ProtectedRoute component={DiscoverInvestments} />} />
              <Route path="/loanview">
                <Route path=":id" element={<ProtectedRoute component={LoanViewForm} />} />
                <Route path=":id/payments" element={<ProtectedRoute component={LoanViewForm} />} />
                <Route path=":id/draws" element={<ProtectedRoute component={LoanViewForm} />} />
                <Route path=":id/documents" element={<ProtectedRoute component={LoanViewForm} />} />
              </Route>
              <Route path="/settings" element={<ProtectedRoute component={Settings} />}>
                <Route path="stripe" element={<ProtectedRoute component={StripeAccountPannel} />} />
              </Route>
              <Route path="/entity">
                <Route index element={<ProtectedRoute component={EntityForm} />} />
                <Route path=":id" element={<ProtectedRoute component={EntityForm} />} />
              </Route>
              <Route path="/entities" element={<ProtectedRoute component={EntityManagement} />} />
              <Route path="/investments" element={<ProtectedRoute component={InvestmentDashboard} />} />
              <Route path="/investment">
                <Route path=":id" element={<ProtectedRoute component={Investment} />} />
                <Route path=":id/offerings" element={<ProtectedRoute component={Investment} />} />
                <Route path="/investment/create" element={<ProtectedRoute component={Details} />} />
                <Route path="/investment/manage">
                  <Route path=":id" element={<ProtectedRoute component={InvestmentTab} />} />
                </Route>
              </Route>
              <Route path="/investor/">
                <Route path="investment-insights">
                  <Route path=":id" element={<ProtectedRoute component={InvestmentInsights} />} />
                </Route>
              </Route>

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </EntityContext.Provider>
      </>
    </AuthProvider>
  );
}

export default App;
