import React, { useEffect, useState } from 'react'
import CBProgressBar from '../../../../shared/CBProgressBar'
import { InvestmentInterestInfo } from '../../../../app/models/Investment/InvestmentInterestInfo';
import { useStore } from '../../../../app/stores/store';
import { Investment } from '../../../../app/models/Investment/Investment';
import { formatCurrency } from '../../../../shared/utils';
import { observer } from 'mobx-react-lite';

interface Props {
    investment: Investment;
    refreshBar?: number;
}

function InterestApprovedFundsBar({ investment, refreshBar }: Props) {

    const [loading, setLoading] = useState(true);
    const [interestInfo, setInterestInfo] = useState(new InvestmentInterestInfo());
    const { investmentStore } = useStore();

    useEffect(() => {
        investmentStore.getInvestmentInterestApproved(investment.id)
            .then(r => {
                setInterestInfo(r);
                setLoading(false);
            })
            .finally(() => { setLoading(false); });
    }, [investmentStore, setInterestInfo, setLoading, investment, refreshBar]);

    return (
        <>
            {!loading && <CBProgressBar title='Approved' progress={interestInfo.pledgedPercentage} message={`${interestInfo.pledgedPercentage.toFixed(2)}% - ${formatCurrency(interestInfo.totalInterestAmount, false, 0)}`} />}
        </>
    )
}

export default observer(InterestApprovedFundsBar)