import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { Segment } from 'semantic-ui-react'
import { HistoricFinancialInfo } from '../../../../app/models/Investment/HistoricFinancialInfo';
import { formatCurrency } from '../../../../shared/utils';

export interface Props {
  data: HistoricFinancialInfo[];
}

function FinancialGraph({ data }: Props) {
  const sortedDate = data.sort((a, b) => {
    if (a.year === b.year) {
      return a.month - b.month;
    }
    return a.year - b.year;
  })

  const valuationData = sortedDate
    .filter((v, i, arr) => arr.findIndex(t => t.asofDate === v.asofDate) === i)
    .map(s => s.valuation);

  const equityData = sortedDate
    .filter((v, i, arr) => arr.findIndex(t => t.asofDate === v.asofDate) === i)
    .map(s => s.equity);

  const groupedByName = sortedDate.reduce((acc, item) => {
    if (!acc[item.name]) acc[item.name] = [];
    acc[item.name].push(item.shareValue);
    return acc;
  }, {} as { [key: string]: number[] });

  const series = [
    {
      name: "Valuation",
      data: valuationData
    },
    {
      name: "Equity",
      data: equityData
    },
    ...Object.keys(groupedByName).map(shareName => ({
      name: `${shareName} Value`,
      data: groupedByName[shareName]
    }))
  ];

  const options: any = {
    chart: {
      height: 150,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    markers: {
      size: 5,
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Valuation History',
      align: 'center'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return formatCurrency(value);
        }
      },
    },
    xaxis: {
      categories: [...new Set(sortedDate.map(s => s.asofDate))],
    }
  };

  return (
    <Segment textAlign='center' basic>
      <ReactApexChart options={options} series={series} type="line" height={350} />
    </Segment>
  )
}

export default FinancialGraph