export interface InvestmentInterestInfo {
    totalInterested: number;
    totalRaiseAmount: number;
    totalAccredited: number;
    averageInvestment: number;
    totalInterestAmount: number;
    pledgedPercentage: number;
}

export class InvestmentInterestInfo implements InvestmentInterestInfo {
    constructor(init?: InvestmentInterestInfo) {
        Object.assign(this, init);
    }
}