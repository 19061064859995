import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { EntityFilterParams } from "../models/common/entityFilterParams";

export default class MarketPlaceStore {

    constructor() {
        makeAutoObservable(this)
    }

    getMarketPlaceDashboard = async (filter: EntityFilterParams) => {
        return agent.MarketPlace.getMarketPlaceDashboard(filter);
    }

    getMarketplaceInvestmentInfo = async (investmentId: number) => {
        return agent.MarketPlace.getMarketplaceInvestmentInfo(investmentId);
    }

    getConnectedInvestments = async(entityId:number) => {
        return agent.MarketPlace.getConnectedInvestments(entityId);
    }
}
