import React from 'react'
import { TabPane } from 'semantic-ui-react'
import { Investment } from '../../../../app/models/Investment/Investment';
import SponsorDistributionGrid from './SponsorDistributionGrid';

interface Props {
    investment: Investment;
}

function Distributions({ investment }: Props) {
    return (
        <TabPane>
            <SponsorDistributionGrid investment={investment} />
        </TabPane>
    )
}

export default Distributions