import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Card, CardGroup, Segment, Statistic, StatisticLabel, StatisticValue } from 'semantic-ui-react'
import { formatStatisticNumber, getFullSizeWidth } from '../../../../shared/utils'
import { InvestmentInterestInfo } from '../../../../app/models/Investment/InvestmentInterestInfo'
import { useStore } from '../../../../app/stores/store'
import { Investment } from '../../../../app/models/Investment/Investment'
import InterestPledgedBar from './InterestPledgedBar'

interface Props {
    investment: Investment;
}

function InterestHeader({ investment }: Props) {
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const cardPadding = { padding: '5px 0px 5px 0px' }

    const [loading, setLoading] = useState(true);
    const [interestInfo, setInterestInfo] = useState(new InvestmentInterestInfo());
    const { investmentStore } = useStore();

    useEffect(() => {
        investmentStore.getInvestmentInterestInfo(investment.id)
            .then(r => {
                setInterestInfo(r);
                setLoading(false);
            })
            .finally(() => { setLoading(false); });
    }, [investmentStore, setInterestInfo, setLoading, investment]);

    return (
        <>
            <Segment clearing loading={loading}>
                {!loading &&
                    <CardGroup itemsPerRow={showFullSize ? 4 : 2} centered>
                        <Card style={cardPadding}>
                            <Statistic color='blue' size='tiny'>
                                <StatisticLabel style={{ fontSize: 'smaller' }}>Total Interested</StatisticLabel>
                                <StatisticValue>{interestInfo.totalInterested}</StatisticValue>
                            </Statistic>
                        </Card>
                        <Card style={cardPadding}>
                            <Statistic color='blue' size='tiny'>
                                <StatisticLabel style={{ fontSize: 'smaller' }}>Estimated Raise Amount</StatisticLabel>
                                <StatisticValue>{formatStatisticNumber(interestInfo.totalRaiseAmount)}</StatisticValue>
                            </Statistic>
                        </Card>
                        <Card style={cardPadding}>
                            <Statistic color='blue' size='tiny'>
                                <StatisticLabel style={{ fontSize: 'smaller' }}>Total Accreditted</StatisticLabel>
                                <StatisticValue>{interestInfo.totalAccredited}</StatisticValue>
                            </Statistic>
                        </Card>
                        <Card style={cardPadding}>
                            <Statistic color='blue' size='tiny'>
                                <StatisticLabel style={{ fontSize: 'smaller' }}>Average Investment Size</StatisticLabel>
                                <StatisticValue>{formatStatisticNumber(interestInfo.averageInvestment)}</StatisticValue>
                            </Statistic>
                        </Card>
                    </CardGroup>
                }
            </Segment>
            {!loading &&
                <InterestPledgedBar interestInfo={interestInfo} />
            }
        </>
    )
}

export default InterestHeader