import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { ServerError } from "../models/common/serverError";
import { CacheNameEnum } from "../../shared/utils";

export default class CommonStore {
    error: ServerError | null = null;
    appLoaded = false;

    constructor() {
        makeAutoObservable(this);
    }

    setServerError = (error: ServerError) => {
        this.error = error;
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }

    mapKeyValue = (result: any[], disableValue?: number) => {
        return result.map(s => ({ key: s.key.toString(), value: s.key, text: s.value, disabled: disableValue && s.key! >= disableValue }))
    }

    getStates = async () => {
        let states = localStorage.getItem(CacheNameEnum.States);
        if (states === null) {
            const result = await agent.Common.getStates();
            states = JSON.stringify(result.map(s => ({ key: s.stateCode, value: s.stateCode, text: s.stateName })));
            localStorage.setItem(CacheNameEnum.States, states);
        }
        return JSON.parse(states);
    }

    getCountries = async () => {
        let countries = localStorage.getItem(CacheNameEnum.Countries);
        if (countries === null) {
            const result = await agent.Common.getCountries();
            countries = JSON.stringify(result.map(s => ({ key: s.id.toString(), value: s.id.toString(), text: s.name })));
            localStorage.setItem(CacheNameEnum.Countries, countries);
        }
        return JSON.parse(countries);
    }

    getResidencyStatus = async () => {
        let status = sessionStorage.getItem(CacheNameEnum.ResidencyStatus);
        if (status === null) {
            const result = await agent.Common.getResidencyStatus();
            status = JSON.stringify(this.mapKeyValue(result));
            sessionStorage.setItem(CacheNameEnum.ResidencyStatus, status);
        }
        return JSON.parse(status);
    }

    getLoanEnums = async () => {
        let loanEnums = sessionStorage.getItem(CacheNameEnum.LoanEnums);
        if (loanEnums === null) {
            const result = await agent.Common.getLoanEnums();
            loanEnums = JSON.stringify({
                calculationMethod: this.mapKeyValue(result.calculationMethod),
                loanType: this.mapKeyValue(result.loanType),
                payPeriod: this.mapKeyValue(result.payPeriod),
            });
            sessionStorage.setItem(CacheNameEnum.LoanEnums, loanEnums);
        }
        return JSON.parse(loanEnums);
    }

    getInvestmentEnums = async () => {
        let investmentEnums = sessionStorage.getItem(CacheNameEnum.InvestmentEnums);
        if (investmentEnums === null) {
            const result = await agent.Common.getInvestmentEnums();
            investmentEnums = JSON.stringify({
                investmentType: this.mapKeyValue(result.investmentType),
                secType: this.mapKeyValue(result.secType),
                interestType: this.mapKeyValue(result.interestType),
                distributionType: this.mapKeyValue(result.distributionType),
                investmentStatus: this.mapKeyValue(result.investmentStatus),
                dealType: this.mapKeyValue(result.dealType),
                propertyType: this.mapKeyValue(result.propertyType),
                bankAccountType: this.mapKeyValue(result.bankAccountType),
                profitSharing: this.mapKeyValue(result.profitSharing),
                returnType: result.returnType,
                shareClassType: this.mapKeyValue(result.shareClassType),
                shareEquityClassName: this.mapKeyValue(result.shareEquityClassName),
                preferredReturns: this.mapKeyValue(result.preferredReturns),
                keyMetricVisualisationSign: this.mapKeyValue(result.keyMetricVisualisationSign),
            });
            sessionStorage.setItem(CacheNameEnum.InvestmentEnums, investmentEnums);
        }
        return JSON.parse(investmentEnums);
    }

    getDocumentTypes = async () => {
        let documentTypes = sessionStorage.getItem(CacheNameEnum.DocumentTypes);
        if (documentTypes === null) {
            const result = await agent.Common.getDocumentTypeEnums();
            documentTypes = JSON.stringify(this.mapKeyValue(result));
            sessionStorage.setItem(CacheNameEnum.DocumentTypes, documentTypes);
        }
        return JSON.parse(documentTypes);
    }

    getOfferingExpiryDurations = async () => {
        let offeringExpiryDurations = sessionStorage.getItem(CacheNameEnum.OfferingExpiryDurations);
        if (offeringExpiryDurations === null) {
            const result = await agent.Common.getOfferingExpiryDurationEnums();
            offeringExpiryDurations = JSON.stringify(this.mapKeyValue(result));
            sessionStorage.setItem(CacheNameEnum.OfferingExpiryDurations, offeringExpiryDurations);
        }
        return JSON.parse(offeringExpiryDurations);
    }

    getEscrowTypes = async () => {
        let types = sessionStorage.getItem(CacheNameEnum.EscrowTypes);
        if (types === null) {
            const result = await agent.Common.getEscrowTypes();
            types = JSON.stringify(result.map(s => ({ key: s.id, value: s.id, text: s.description })));
            sessionStorage.setItem(CacheNameEnum.EscrowTypes, types);
        }
        return JSON.parse(types);
    }

    getFeeEnums = async () => {
        let feeEnums = sessionStorage.getItem(CacheNameEnum.FeeEnums);
        if (feeEnums === null) {
            const result = await agent.Common.getFeeEnums();
            feeEnums = JSON.stringify({
                feeTypes: this.mapKeyValue(result.feeTypes),
                calculationMethod: this.mapKeyValue(result.calculationMethod),
            });
            sessionStorage.setItem(CacheNameEnum.FeeEnums, feeEnums);
        }
        return JSON.parse(feeEnums);
    }

    getDistributionParams = async () => {
        return agent.Common.getDistributionParams();
    }
}