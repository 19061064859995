import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { TabPane, Segment, Form, Button, Icon, Divider, Header } from 'semantic-ui-react';
import CurrencyInput from '../../../../app/common/form/CurrencyInput';
import { useStore } from '../../../../app/stores/store';
import SelectInput from '../../../../app/common/form/SelectInput';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import TextInput from '../../../../app/common/form/TextInput';
import { InputMasks } from '../../../../shared/InputMasks';
import DateInput from '../../../../app/common/form/DateInput';
import { booleanOptions, getFloatValue } from '../../../../shared/utils';
import { NewMonthlyInvestmentFinancialFormValues } from '../../../../app/models/Investment/Manage/NewMonthlyInvestmentFinancial';

interface Props {
    investmentId: number;
    continueNextTab: () => void;
}

function Debt({ investmentId, continueNextTab }: Props) {
    const emptyOption = { key: '' };
    const [interestTypeOptions, setInterestTypeOptions] = useState([emptyOption]);
    const { commonStore, investmentStore } = useStore();
    const [monthlyFinancial, setMonthlyFinancial] = useState(new NewMonthlyInvestmentFinancialFormValues());
    const [loading, setLoading] = useState(true);
    const [continueButtonCliked, setContinueButtonCliked] = useState(false);

    useEffect(() => {
        commonStore.getInvestmentEnums().then(r => {
            setInterestTypeOptions(r.interestType);
        })

        investmentStore.getDebtInfo(investmentId).then((r) => {
            if (r.id === 0) {
                r.loanToValue = '';
                r.interestRate = '';
                r.term = '';
                r.interestOnly = '';
                r.interestYears = '';
                r.ownedSince = '';
                r.debtCoverage = '';
                r.annualDebtService = '';
                r.totalLoan = '';
                r.loanStartDate = '';
            }
            const result = new NewMonthlyInvestmentFinancialFormValues(r);
            setMonthlyFinancial(result);
        })
            .finally(() => setLoading(false));
    }, [investmentId, setMonthlyFinancial, investmentStore, setLoading, commonStore, setInterestTypeOptions]);

    const validationSchema = yup.object({
        lender: yup
            .string()
            .required('Lender is required'),
        term: yup
            .number()
            .required('Term is required')
            .positive('Term must be greater than 0'),
        ownedSince: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Owned Since Date is required"),
        interestRate: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Interest Rate is required')
            .positive('Interest Rate must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        interestType: yup
            .number()
            .min(1, 'Interest Type is required'),
        loanToValue: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Loan to Value is required')
            .positive('Loan to Value must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        interestOnly: yup
            .string()
            .required('Interest Only is required'),
        interestYears: yup
            .number()
            .when('interestOnly', {
                is: "1",
                then: yup.number()
                    .required('Years is required')
                    .positive('Years must be greater than 0'),
            }),
        debtCoverage: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Debt Coverage is required')
            .positive('Debt Coverage must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        annualDebtService: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Annual Debt Service is required')
            .positive('Annual Debt Service must be greater than 0'),
        totalLoan: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Total Loan is required'),
        loanStartDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Loan Start Date is required"),
    });

    const handleFormSubmit = async (values: any, setSubmitting: any) => {
        values.investmentId = investmentId;
        investmentStore.saveInvestmentDebt(new NewMonthlyInvestmentFinancialFormValues(values))
            .then(r => {
                toast.success("Success!", { theme: "colored" });
                if (continueButtonCliked) continueNextTab();
            })
            .catch(err => {
                toast.error("There was an issue saving the debt info.", { theme: "colored" });
            })
            .finally(() => setSubmitting(false));
    }
    return (
        <TabPane loading={loading}>
            <Segment clearing>
                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={monthlyFinancial}
                    onSubmit={(values, { setSubmitting }) => {
                        handleFormSubmit(values, setSubmitting);
                    }}
                >
                    {({ values, handleSubmit, isSubmitting }) => (
                        <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            <Form.Group widths='equal'>
                                <SelectInput options={interestTypeOptions} placeholder='Financing Type' name='interestType' showRequired />
                                <CurrencyInput placeholder='Loan to Value' name='loanToValue' maxLength={6} icon={<Icon name='percent' />} showRequired />
                                <CurrencyInput placeholder='Interest Rate' name='interestRate' maxLength={6} icon={<Icon name='percent' />} showRequired />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <CurrencyInput placeholder='Loan Term (Years)' name='term' maxLength={4} showRequired />
                                <SelectInput options={booleanOptions} placeholder='Interest Only' name='interestOnly' showRequired />
                                {!!values.interestOnly && <CurrencyInput placeholder='Years' name='interestYears' maxLength={4} showRequired />}
                            </Form.Group>

                            <Divider horizontal section>
                                <Header as='h4'>
                                    Loan Information
                                </Header>
                            </Divider>
                            <Form.Group widths='equal'>
                                <TextInput placeholder='Lender' name='lender' maxLength={255} showRequired />
                                <DateInput placeholder='Owned Since' name='ownedSince' mask={InputMasks.date} showRequired />
                                <DateInput placeholder='Loan Start Date' name='loanStartDate' mask={InputMasks.date} showRequired />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <CurrencyInput placeholder='Debt Coverage Ratio' name='debtCoverage' maxLength={6} showRequired />
                                <CurrencyInput placeholder='Annual Debt Service' name='annualDebtService' maxLength={16} thousandSeparator=',' decimalScale={2} prefix='$' showRequired />
                                <CurrencyInput placeholder='Total Loan Balance' name='totalLoan' maxLength={16} thousandSeparator=',' decimalScale={2} prefix='$' showRequired />
                            </Form.Group>

                            <br />
                            <Button disabled={isSubmitting} loading={isSubmitting} floated='right' color='green' type='submit' content='Continue' onClick={() => setContinueButtonCliked(true)} />
                            <Button disabled={isSubmitting} loading={isSubmitting} floated='right' primary type='submit' content='Save' />
                        </Form>
                    )}
                </Formik>
            </Segment>
        </TabPane>
    )
}

export default Debt