import { Button, Grid, GridColumn, GridRow, Header, HeaderContent, Segment, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import { Investment } from "../../../../app/models/Investment/Investment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useStore } from "../../../../app/stores/store";
import EntityContext from "../../../../app/context/entityContext";
import { MonthlyInvestmentFinancial } from "../../../../app/models/Investment/MonthlyInvestmentFinancial";
import { toast } from 'react-toastify';
import { createGridInitialState, formatCurrency, handleGridNavigation, handleGridSort } from "../../../../shared/utils";
import { formatDate } from "../../../../shared/utils";
import { InvestmentFinancialsQueryFilter } from "../../../../app/models/common/InvestmentFinancialsQueryFilter";
import GridFooter from "../../../../shared/GridFooter";
import React from "react";
import { PageParams } from "../../../../app/models/common/PagedResult";
import FinancialsForm from "./FinancialsForm";
import EmptyGridMessage from "../../../../shared/EmptyGridMessage";
import FinancialValidation from "./FinancialValidation";

interface Params {
    investment: Investment;
}

function FinancialsGrid({ investment }: Params) {
    const { investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [financialId, setFinancialId] = useState(0);
    const [monthlyFinancials, setMonthlyFinancials] = useState<MonthlyInvestmentFinancial[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentInvestment, setCurrentInvestment] = useState<Investment>(new Investment());
    const [addNew, setAddNew] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const assetTabMessage = "To add financial records, please first go to the Asset Details tab and enter an Initial Valuation. This step is required to enable accurate share value calculations and financial updates."

    const initialState: InvestmentFinancialsQueryFilter = createGridInitialState({
        entityId: entity.id,
        investmentId: investment.id,
        sortIndex: 'Date',
        sortOrder: 'descending',
    });
    const [params, setParams] = useState(initialState);

    const loadMonthlyFinancials = useCallback(() => {
        setLoading(true);
        investmentStore.getInvestmentMonthlyFinancials(
            params)
            .then((r) => {
                setTotalRecords(r.totalRecords);
                setMonthlyFinancials(r.data);
            })
            .catch((e) => {
                toast.error("There was an error getting the investment financials", { theme: "colored" });
            })
            .finally(() => setLoading(false));

    }, [investmentStore, params])

    useEffect(() => {
        loadMonthlyFinancials();
    }, [loadMonthlyFinancials]);

    const editFinancial = (financialId: number) => {
        investmentStore.getMonthlyInvestmentFinancial(entity.id, investment.id, financialId)
            .then((r) => {
                setCurrentInvestment(r);
                setFinancialId(financialId);
                setShowEditor(true);
                setAddNew(false);
            })
    }

    const addFinancial = async () => {
        if (monthlyFinancials.length === 0) {
            const isValidAcquisition = checkInvestmentAcquisition();
            if (await isValidAcquisition) {
                getNewFinancial();
            } else {
                setShowErrorDialog(true);
            }
        } else {
            getNewFinancial();
        }
    };

    const checkInvestmentAcquisition = async () => {
        const result = await investmentStore.getInvestmentById(entity.id, investment.id)
        return Number(result.valueAtAcquisition) > 0;
    };

    const getNewFinancial = () => {
        investmentStore.getNewMonthlyInvestmentFinancial(entity.id, investment.id)
            .then((r) => {
                const update = { ...investment };
                update.currentFinancialInfo = r;
                update.filteredFinancialInfo = r;
                setCurrentInvestment(update);
                setFinancialId(0);
                setShowEditor(true);
                setAddNew(true);
            });
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }
    const handleSort = (clickedColumn: string) => {
        if (monthlyFinancials.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }
    return (
        <>
            <Segment clearing loading={loading}>
                {!showEditor ?
                    <>
                        <Grid columns={2}>
                            <GridRow>
                                <GridColumn verticalAlign="middle">
                                    <Header as='h3'>
                                        <HeaderContent>Financials</HeaderContent>
                                    </Header>
                                </GridColumn>
                                <GridColumn verticalAlign="middle" textAlign="right"><Button onClick={() => { addFinancial(); }} primary content="Add New"></Button></GridColumn>
                            </GridRow>
                        </Grid>
                        <Table sortable singleLine striped>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell onClick={() => handleSort("AsOfDate")} sorted={params.sortIndex === "AsOfDate" && monthlyFinancials.length > 0 ? params.sortOrder : undefined}>As of Date</TableHeaderCell>
                                    <TableHeaderCell onClick={() => handleSort("NetIncome")} sorted={params.sortIndex === "NetIncome" && monthlyFinancials.length > 0 ? params.sortOrder : undefined}>Net Income</TableHeaderCell>
                                    <TableHeaderCell onClick={() => handleSort("NOI")} sorted={params.sortIndex === "NOI" && monthlyFinancials.length > 0 ? params.sortOrder : undefined}>NOI</TableHeaderCell>
                                    <TableHeaderCell onClick={() => handleSort("NetIncome")} sorted={params.sortIndex === "NetIncome" && monthlyFinancials.length > 0 ? params.sortOrder : undefined}>Net Income (Anualized)</TableHeaderCell>
                                    <TableHeaderCell onClick={() => handleSort("NOI")} sorted={params.sortIndex === "NOI" && monthlyFinancials.length > 0 ? params.sortOrder : undefined}>NOI (Annualized)</TableHeaderCell>
                                    <TableHeaderCell onClick={() => handleSort("Valuation")} sorted={params.sortIndex === "Valuation" && monthlyFinancials.length > 0 ? params.sortOrder : undefined}>Valuation</TableHeaderCell>
                                    <TableHeaderCell>Actions</TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {monthlyFinancials?.map((f, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell collapsing>{formatDate(f.asofDate)}</TableCell>
                                            <TableCell>{formatCurrency(f.netIncome)}</TableCell>
                                            <TableCell>{formatCurrency(f.noi)}</TableCell>
                                            <TableCell>{formatCurrency(f.annualizedNetIncome)}</TableCell>
                                            <TableCell>{formatCurrency(f.annualizedNOI)}</TableCell>
                                            <TableCell>{formatCurrency(f.valuation)}</TableCell>
                                            <TableCell><Button onClick={() => { editFinancial(f.id) }} primary content='Edit'></Button></TableCell>
                                        </TableRow>)
                                })}
                                {!loading && monthlyFinancials.length === 0 && <EmptyGridMessage colSpan={7} message='No financial records available' />}
                            </TableBody>
                            {!loading && monthlyFinancials.length > 0 &&
                                <GridFooter colSpan={7} params={params} totalRecords={totalRecords} onChange={gridChange} />
                            }
                        </Table>
                    </>
                    :
                    <FinancialsForm investment={currentInvestment}
                        onCancel={() => {
                            loadMonthlyFinancials();
                            setShowEditor(false);
                        }}
                        editFinancialId={financialId} addNew={addNew} allowEdit={true} />
                }
            </Segment >
            {showErrorDialog && <FinancialValidation show={showErrorDialog}
                header="Review Asset Details Tab" message={assetTabMessage} closeModal={() => setShowErrorDialog(false)} />}
        </>
    )
}
export default FinancialsGrid