import React from 'react'
import CBProgressBar from '../../../../shared/CBProgressBar'
import { InvestmentInterestInfo } from '../../../../app/models/Investment/InvestmentInterestInfo';
import { formatCurrency } from '../../../../shared/utils';

interface Props {
    interestInfo: InvestmentInterestInfo;
}

function InterestPledgedBar({ interestInfo }: Props) {

    return (
        <CBProgressBar title='Pledged' progress={interestInfo.pledgedPercentage} message={`${interestInfo.pledgedPercentage.toFixed(2)}% - ${formatCurrency(interestInfo.totalInterestAmount, false, 0)}`} />
    )
}

export default InterestPledgedBar