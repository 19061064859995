import { useEffect, useState } from 'react'
import { Checkbox, Grid, Header, Icon, Input, Segment, Table } from 'semantic-ui-react'
import { useMediaQuery } from 'react-responsive';
import { createGridInitialState, formatCurrency, formatPhoneNumber, getFullSizeWidth, getRandomNumber, handleGridNavigation, handleGridSort } from '../../../../shared/utils';
import { Investment } from '../../../../app/models/Investment/Investment';
import { useStore } from '../../../../app/stores/store';
import { InvestmentQueryFilter } from '../../../../app/models/common/InvestmentQueryFilter';
import { PageParams } from '../../../../app/models/common/PagedResult';
import EmptyGridMessage from '../../../../shared/EmptyGridMessage';
import GridFooter from '../../../../shared/GridFooter';
import ConfirmButton from '../../../../shared/ConfirmButton';
import { InvestmentInterestGrid } from '../../../../app/models/Investment/InvestmentInterestGrid';
import { useDebounce } from '../../../../shared/useDebounce';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

interface Props {
    investment: Investment;
    onApproval: () => void;
}

function InterestGrid({ investment, onApproval }: Props) {

    const [loading, setLoading] = useState(true);
    const { investmentStore } = useStore();

    const [interestInvestors, setInterestInvestors] = useState([new InvestmentInterestGrid()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })
    const [search, setSearch] = useState<number>(0);
    const debounceSearch = useDebounce<number>(search, 250);
    const [searchValue, setSearchValue] = useState<string>("");
    const [approvalInterestId, setApprovalInterestId] = useState(0);
    const [checked, setChecked] = useState(false);

    const initialState: InvestmentQueryFilter = createGridInitialState({
        investmentId: investment.id,
        showAll: false,
        sortIndex: 'EntityName',
        sortOrder: 'ascending',
        searchValue: ""
    });

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        investmentStore.getInvestmentInterest(params)
            .then(r => {
                setTotalRecords(r.totalRecords);
                setInterestInvestors(r.data);
            })
            .finally(() => { setLoading(false); });
    }, [investmentStore, params, setLoading]);

    useEffect(() => {
        if (debounceSearch > 0) {
            setParams(prevState => ({ ...prevState, search: searchValue }));
        }
    }, [debounceSearch, searchValue]);


    const handleSort = (clickedColumn: string) => {
        if (interestInvestors.length === 0) return;
        setParams(handleGridSort(params, clickedColumn));
    }

    const gridChange = (updatedParams: PageParams) => {
        setParams(handleGridNavigation(params, updatedParams));
    }

    const handleConfirmApproval = () => {
        investmentStore.approveInvestmentInterest(investment.id, approvalInterestId)
            .then(r => {
                let approveAction = false;
                const index = interestInvestors.findIndex(item => item.id === approvalInterestId);
                if (index !== -1) {
                    const updatedData = [...interestInvestors];
                    approveAction = !updatedData[index].approved;
                    updatedData[index].approved = approveAction;
                    setInterestInvestors(updatedData);
                }
                onApproval();
                toast.success(`Interested investor ${approveAction ? 'approved' : 'unapproved'}!`, { theme: "colored" });
            })
            .catch(err => {
                toast.error(err.response.data, { theme: "colored" });
            })
    }

    const approveInvestor = (interestId: number, checked: boolean) => {
        setChecked(checked);
        setApprovalInterestId(interestId);
        document.getElementById("btnApprove")?.click();
    }

    const searchChange = (event: any) => {
        setSearchValue(event.target.value);
        setSearch(getRandomNumber);
    }

    return (
        <Segment clearing loading={loading}>
            <Grid columns={3}>
                <Grid.Row>
                    <Grid.Column width={showFullSize ? 6 : 5} verticalAlign='middle'>
                        <Header>Interest Investors</Header>
                    </Grid.Column>
                    <Grid.Column textAlign='center' verticalAlign='middle' width={showFullSize ? 5 : 1}>
                        <Input onChange={(e) => searchChange(e)} placeholder='Search...' icon='search' />
                    </Grid.Column>
                    <Grid.Column textAlign='right' verticalAlign='middle' width={showFullSize ? 5 : 10}>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table sortable>
                {showFullSize &&
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => handleSort("EntityName")} sorted={params.sortIndex === "EntityName" && interestInvestors.length > 0 ? params.sortOrder : undefined}> Name</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("ShareClass")} sorted={params.sortIndex === "ShareClass" ? params.sortOrder : undefined}>Share Class</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Amount")} sorted={params.sortIndex === "Amount" ? params.sortOrder : undefined}>Amount</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("AccreditationDescription")} sorted={params.sortIndex === "AccreditationDescription" ? params.sortOrder : undefined}>Accreditation</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("FundingMethodDescription")} sorted={params.sortIndex === "FundingMethodDescription" ? params.sortOrder : undefined}>Funding Method</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Email")} sorted={params.sortIndex === "Email" ? params.sortOrder : undefined}>Email</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("CellPhone")} sorted={params.sortIndex === "CellPhone" ? params.sortOrder : undefined}>Phone</Table.HeaderCell>
                            <Table.HeaderCell onClick={() => handleSort("Approved")} sorted={params.sortIndex === "Approved" ? params.sortOrder : undefined}>Approved</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                }

                <Table.Body>
                    {!loading && interestInvestors.map((interest, i) => {
                        if (showFullSize) {
                            return <Table.Row key={i}>
                                <Table.Cell>{interest.entityName}</Table.Cell>
                                <Table.Cell>{interest.shareClass}</Table.Cell>
                                <Table.Cell textAlign='right'>{formatCurrency(interest.amount)}</Table.Cell>
                                <Table.Cell>{interest.accreditationDescription}</Table.Cell>
                                <Table.Cell>{interest.fundingMethodDescription}</Table.Cell>
                                <Table.Cell>{interest.email}</Table.Cell>
                                <Table.Cell>{formatPhoneNumber(interest.cellPhone)}</Table.Cell>
                                <Table.Cell textAlign='center'>
                                    <Checkbox toggle checked={interest.approved}
                                        onChange={(e, { checked }) => approveInvestor(interest.id, checked!)} />
                                </Table.Cell>
                            </Table.Row>
                        }
                        else {
                            return <Table.Row key={i}>
                                <Table.Cell>
                                    {params.sortOrder ? <Icon name={`sort ${params.sortOrder}`} /> : ''}
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={6}>
                                                <label onClick={() => handleSort("EntityName")}>Name:</label><br />
                                                <label onClick={() => handleSort("ShareClass")}>Share Class:</label><br />
                                                <label onClick={() => handleSort("Amount")}>Amount:</label><br />
                                                <label onClick={() => handleSort("AccreditationDescription")}>Accreditation:</label><br />
                                                <label onClick={() => handleSort("FundingMethodDescription")}>Funding Method:</label><br />
                                                <label onClick={() => handleSort("Email")}>Email:</label><br />
                                                <label onClick={() => handleSort("CellPhone")}>Email:</label><br />
                                                <label>Approved:</label>
                                            </Grid.Column>
                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                <div style={{ width: '45vw' }}>
                                                    {interest.entityName}<br />
                                                    {interest.shareClass}<br />
                                                    {interest.amount}<br />
                                                    {interest.accreditationDescription}<br />
                                                    {interest.fundingMethodDescription}<br />
                                                    {interest.email}<br />
                                                    {interest.cellPhone}<br />
                                                    <Checkbox toggle checked={interest.approved}
                                                        onChange={(e, { checked }) => approveInvestor(interest.id, checked!)} />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        }
                    })}
                    {!loading && interestInvestors.length === 0 && <EmptyGridMessage colSpan={showFullSize ? 8 : 1} message='There are no individuals who have expressed interest' />}
                </Table.Body>
                {!loading && interestInvestors.length > 0 &&
                    <GridFooter colSpan={showFullSize ? 8 : 1} params={params} totalRecords={totalRecords} onChange={gridChange} />
                }
            </Table>

            <ConfirmButton id={1} buttonId='btnApprove' value={`${checked ? 'Approve' : 'Unapprove'}`} content={`Are you sure you want to ${checked ? 'approve' : 'unapprove'}?`} handleConfirm={handleConfirmApproval} hideButton />

        </Segment >
    )
}

export default observer(InterestGrid)