import { Button, Header, Placeholder, PlaceholderLine, PlaceholderParagraph, Table, TableCell, TableRow } from "semantic-ui-react";
import { formatCurrency } from "../../shared/utils";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { InvestmentShareInfo } from "../../app/models/Investment/InvestmentShareInfo";
import React from "react";

interface Params {
    investmentId: number
}

function ShareClassPanelComponent({ investmentId }: Params) {

    const [loading, setLoading] = useState(true);
    const [shareInfo, setShareInfo] = useState<InvestmentShareInfo[]>([]);
    const { investmentStore } = useStore();

    useEffect(() => {
        setLoading(true);
        investmentStore.getInvestmentShareInfo(investmentId)
            .then(r =>{ 
                setShareInfo(r);
            })
            .finally(() => setLoading(false));
    }, [investmentId, investmentStore])

    return (
        <>
            {loading &&
                <Placeholder>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                    <PlaceholderParagraph>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </PlaceholderParagraph>
                </Placeholder>
            }
            {!loading &&
                <>
                    {shareInfo.length > 0 ?

                        <Table className='investmentMarketingPanel' striped color='green'>
                            {shareInfo?.map((s, i) =>
                            (
                                <TableRow className='insightsTableText'>
                                    {false && <TableCell collapsing>
                                        <Button size='mini' primary color='blue' content='Invest Now'></Button>
                                    </TableCell>
                                    }
                                    <TableCell text-align='right' collapsing>
                                        <Header as='h4'>{s.name}</Header>
                                    </TableCell>
                                    <TableCell>
                                        <div>{formatCurrency(s.minimumInvestment)} Minimum</div>
                                    </TableCell>
                                    <TableCell>
                                        {s.split.map((split, i) => (
                                            <>
                                                <div>{split.limit} {split.description}</div>
                                            </>
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        <div>{s.typeOfEquity}</div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Table>
                        :
                        <div>No share class data is available for this investment</div>
                    }
                </>
            }
        </>
    )
}
export default ShareClassPanelComponent