import { Hurdle } from "./Hurdle";
import { ShareHolder } from "./Shareholder";

export interface ShareClassification {
  id: number;
  shareClass: string;
  initialShareValue: number;
  shareValue: number | string;
  currentShareValue: number;
  shareCount: number | string;
  shareType: number;
  shareHolderName: string;
  percentageOwnership: number;
  costBasis: number;
  totalValue: number;
  investedAmount: number;
  capitalRaised: number | string;

  legalOwnershipPercentage: number | string;
  legalOwnershipPercentageError: boolean;
  typeOfEquity: string;
  minimumInvestmentAmount: number | string;
  maximumInvestmentAmount: number | string;
  sortOrder: number;
  deleted: boolean;
  investmentId: number;
  shareHolders?: ShareHolder[];
  shareHolderShareCountError?: boolean;

  preferredReturnType?: number;
  returnPercentage?: number | string;
  catchUpOnPreferredReturns?: number | null;

  hurdles?: Hurdle[];

  equityPercentage: number;
}

export class ShareClassification implements ShareClassification {
  constructor(init?: ShareClassification) {
    Object.assign(this, init);
  }
}