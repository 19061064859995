import React, { useState } from 'react'
import { Investment } from '../../../../app/models/Investment/Investment'
import InterestGrid from './InterestGrid';
import InterestHeader from './InterestHeader';
import InterestApprovedFundsBar from './InterestApprovedFundsBar';
import { getRandomNumber } from '../../../../shared/utils';

interface Props {
    investment: Investment;
}

function InterestInfo({ investment }: Props) {
    const [refreshBar, setRefreshBar] = useState(0);
    const refreshApprovedBar = () => {
        setRefreshBar(getRandomNumber);
    }
    return (
        <>
            <InterestHeader investment={investment} />

            <InterestGrid investment={investment} onApproval={refreshApprovedBar} />

            <InterestApprovedFundsBar investment={investment} refreshBar={refreshBar} />
        </>
    )
}

export default InterestInfo