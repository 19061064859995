import { Button, Container, Grid, GridColumn, GridRow, Header, HeaderContent, HeaderSubheader, Icon, Segment, Table, TableBody, TableCell, TableRow } from "semantic-ui-react"
import { useAuth0 } from "@auth0/auth0-react"
import { useParams } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { useStore } from "../../app/stores/store"
import GoogleMapsComponent from "./googlemaps"
import { InvestmentSummaryView } from "../../app/models/GpLanding/InvestmentSummaryView";
import InvestmentImages from "../investment/Details/InvestmentImages";
import OverviewPanelComponent from "./overviewPanel";
import AssetDetailsPanelComponent from "./assetDetailsPanel";
import DebtFinancingPanelComponent from "./debtFinancingPanel";
import TermsFeesPanelComponent from "./termsFeesPanel";
import ShareClassPanelComponent from "./shareClassPanel";
import KeyMetricsHeaderPanelComponent from "./keyMetricsHeaderPanel"
import ProjectedReturnsPanelComponent from "./projectedReturnsPanel"
import InvestmentDocumentsPanel from "./investmentDocumentsPanel"
import SponsorshipTeamPanel from "./sponsorshipTeamPanel"
import React from "react"
import GpMarketingPageLink from "../../shared/GpMarketingPageLink"
import EntityContext from "../../app/context/entityContext"

function InvestmentInsights() {

    const { id } = useParams();
    const { isAuthenticated } = useAuth0();
    const { investmentInsightsStore } = useStore();
    const { investmentStore } = useStore();
    const [investment, setInvestment] = useState(new InvestmentSummaryView());
    const [loading, setLoading] = useState(true);
    const [showInterestButton, setShowInterestButton] = useState(false);
    const { entity } = useContext(EntityContext);


    useEffect(() => {
        investmentInsightsStore.getOverview(Number(id))
            .then(r => setInvestment(r))
            .finally(() => setLoading(false))
    }, [id, isAuthenticated, investmentInsightsStore, setLoading]);

    useEffect(() => {
        if (entity !== null) {
            investmentStore.getIsEntityHasInvestmentInterest(entity.id, Number(id))
                .then((r) => setShowInterestButton(r))
        }
    }, [entity, id, investmentStore])


    return (
        <>
            <Header as='h1'>
                <HeaderContent>{investment.name}</HeaderContent>
                <HeaderSubheader>
                    <GpMarketingPageLink sponsorEntityId={investment.sponsorEntityId}></GpMarketingPageLink>
                </HeaderSubheader>
            </Header>
            <Segment clearing loading={loading}>
                <Grid columns='equal' relaxed className='landingPageBackground'>
                    <GridRow style={{ borderStyle: 'none', paddingTop: 0 }}>
                        <Table style={{ borderStyle: 'outset', backgroundColor: 'white' }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <KeyMetricsHeaderPanelComponent investmentId={Number(id)}></KeyMetricsHeaderPanelComponent>
                                    </TableCell>
                                    <TableCell textAlign='right' collapsing>
                                        {showInterestButton && <Button primary color='blue' content="I'm Interested"></Button>}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                    </GridRow>
                    <GridRow>
                        <GridColumn>
                            <Header as='h3' image >
                                <Icon name='image'></Icon>
                                <Header.Content>Photo Galllery</Header.Content>
                            </Header>
                            <InvestmentImages headerText={""} investmentId={Number(id)} entityId={investment.sponsorEntityId} enableInvestmentLink={false} showThumbnails={false}></InvestmentImages>
                            <Header as='h3' image>
                                <Icon name='info circle'></Icon>
                                <Header.Content>About Offering</Header.Content>
                            </Header>
                            <Container style={{ height: 200, padding: 20 }} className='investmentMarketingPanel'>
                                <div>
                                    <p>{investment.description}</p>
                                </div>
                            </Container>
                        </GridColumn>
                        <GridColumn>
                            <Header as='h3' image style={{ marginBottom: 0 }}>
                                <Icon name='info circle'></Icon>
                                <Header.Content>Overview</Header.Content></Header>
                            <OverviewPanelComponent investmentId={Number(id)}></OverviewPanelComponent>
                            <Header as='h3' image >
                                <Icon name='map marker alternate'></Icon>
                                <Header.Content>Location</Header.Content>
                            </Header>
                            <Container className='investmentMarketingPanel'>
                                <GoogleMapsComponent investment={investment}></GoogleMapsComponent>
                            </Container>
                        </GridColumn>
                    </GridRow>

                    <div className="ui divider"></div>
                    <GridRow>
                        <GridColumn widths={2}>
                            <Header as='h3' image style={{ marginBottom: 0 }}>
                                <Icon name='line graph'></Icon>
                                <Header.Content>Projected Investor Returns</Header.Content>
                            </Header>
                            <ProjectedReturnsPanelComponent investmentId={Number(id)}></ProjectedReturnsPanelComponent>
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn widths={2}>
                            <Header as='h3' image style={{ marginBottom: 0 }}>
                                <Icon name='pie graph'></Icon>
                                <Header.Content>Share Classes</Header.Content>
                            </Header>
                            <ShareClassPanelComponent investmentId={Number(id)} />
                        </GridColumn>
                    </GridRow>
                    <div className="ui divider"></div>

                    <GridRow>
                        <GridColumn>
                            <Header as='h3' image style={{ marginBottom: 0 }}>
                                <Icon name='building'></Icon>
                                <Header.Content>Asset Details</Header.Content>
                            </Header>
                            <AssetDetailsPanelComponent investmentId={Number(id)}></AssetDetailsPanelComponent>
                        </GridColumn>
                        <GridColumn>
                            <Header as='h3' image style={{ marginBottom: 0 }}>
                                <Icon name='line graph'></Icon>
                                <Header.Content>Debt Financing</Header.Content>
                            </Header>
                            <DebtFinancingPanelComponent investmentId={Number(id)}></DebtFinancingPanelComponent>
                            <Header as='h3' image style={{ marginBottom: 0 }}>
                                <Icon name='group'></Icon>
                                <Header.Content>General Partner Team</Header.Content>
                            </Header>
                            <SponsorshipTeamPanel investmentId={Number(id)} enableHeader={false}></SponsorshipTeamPanel>
                        </GridColumn>
                        <GridColumn>
                            <Header as='h3' image style={{ marginBottom: 0 }}>
                                <Icon name='money'></Icon>
                                <Header.Content>Terms and Fees</Header.Content>
                            </Header>
                            <TermsFeesPanelComponent investmentId={Number(id)}></TermsFeesPanelComponent>
                        </GridColumn>
                    </GridRow>
                    <GridRow>
                        <GridColumn widths={2}>
                            <InvestmentDocumentsPanel investmentId={Number(id)} sponsorEntityId={investment.sponsorEntityId} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </Segment>
        </>

    )
}

export default InvestmentInsights