import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button, Header, Segment, Tab } from 'semantic-ui-react';
import Details from '../Details/Details';
import AssetDetails from '../AssetDetails/AssetDetails';
import { useStore } from '../../../../app/stores/store';
import EntityContext from '../../../../app/context/entityContext';
import { InvestmentFormValues, InvestmentStatus } from '../../../../app/models/Investment/Investment';
import Funding from '../Funding/Funding';
import TermsAndFees from '../TermsAndFees/TermsAndFees';
import Debt from '../Debt/Debt';
import KeyMetricsList from '../KeyMetrics/KeyMetricsList';
import InvestmentDocuments from '../../Documents/InvestmentDocuments';
import InvestmentImages from '../Image/InvestmentImages';
import ShareManagement from '../Shares/ShareManagement';
import InterestInfo from '../Interest/InterestInfo';
import FinancialsGrid from '../Financials/FinancialsGrid';
import Distributions from '../Distributions/Distributions';
import { getRandomNumber } from '../../../../shared/utils';

function InvestmentTab() {
    const { id } = useParams();
    const [investment, setInvestment] = useState(new InvestmentFormValues());
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const { investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [refreshTab, setRefreshTab] = useState(0);

    useEffect(() => {
        investmentStore.getInvestmentById(entity.id, Number(id)).then((r) => {
            const result = new InvestmentFormValues(r);
            setInvestment(result);
        })
            .finally(() => setLoading(false));
    }, [id, setInvestment, entity, investmentStore, setLoading, refreshTab]);

    const continueNextTab = () => {
        setActiveIndex(activeIndex + 1);
    }

    const updateTab = (tabIndex: number) => {
        setRefreshTab(getRandomNumber());
        setActiveIndex(tabIndex);
    }

    var panes = [
        {
            menuItem: { key: 'Details', content: 'Details' },
            render: () => <Details investmentId={Number(id)} continueNextTab={continueNextTab} refreshTab={updateTab} />
        },
        {
            menuItem: { key: 'AssetDetails', content: 'Asset Details' },
            render: () => <AssetDetails investmentId={Number(id)} continueNextTab={continueNextTab} />
        },
        {
            menuItem: { key: 'Funding', content: 'Funding' },
            render: () => <Funding investmentId={Number(id)} continueNextTab={continueNextTab} />
        },
        {
            menuItem: { key: 'Shares', content: 'Shares' },
            render: () => <ShareManagement investmentId={Number(id)} />
        },
        {
            menuItem: { key: 'TermsAndFees', content: 'Terms and Fees' },
            render: () => <TermsAndFees investmentId={Number(id)} continueNextTab={continueNextTab} />
        },
        {
            menuItem: { key: 'Debt', content: 'Debt' },
            render: () => <Debt investmentId={Number(id)} continueNextTab={continueNextTab} />
        },
        {
            menuItem: { key: 'KeyMetrics', content: 'Key Metrics' },
            render: () => <KeyMetricsList investmentId={Number(id)} />
        },
        {
            menuItem: { key: 'Documents', content: 'Documents' },
            render: () => <InvestmentDocuments investment={investment} />
        },
        {
            menuItem: { key: 'Photos', content: 'Photos' },
            render: () => <InvestmentImages investment={investment} />
        },
        {
            menuItem: { key: 'Interest', content: 'Interest' },
            render: () => <InterestInfo investment={investment} />
        },
        {
            menuItem: { key: 'Financials', content: 'Financials' },
            render: () => <FinancialsGrid investment={investment} />
        },
        {
            menuItem: { key: 'Distributions', content: 'Distributions' },
            render: () => <Distributions investment={investment} />
        }
    ];

    const handleTabChange = (index: number) => setActiveIndex(index);

    if (investment!.investmentStatus < InvestmentStatus.interested) {
        panes = panes.filter(x => x.menuItem.key !== "Interest" && x.menuItem.key !== "Distributions");
    }

    if (investment!.currentFinancialInfo != null) {
        panes = panes.filter(x => x.menuItem.key !== "Debt");
    }

    return (
        <Segment basic loading={loading}>
            <Header as='h3' color='blue'>
                {investment.name}
                <Button floated='right' content='Back to Investments' as={Link} to='/investments' />
            </Header>
            <Tab panes={panes} className='tabScroll' renderActiveOnly activeIndex={activeIndex} onTabChange={(e, { activeIndex }) => handleTabChange(Number(activeIndex))} />
        </Segment>
    )
}

export default InvestmentTab