import { Company } from "./company";
import { Individual } from "./individual";

export enum Role {
  Unknown = 0,
  LoanCreation = 1,
  ManageStatement = 2
}

export interface Entity {
  id: number,
  entityType: number,
  name: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  stateCode: string,
  zip: string,
  cellPhone: string,
  workPhone: string,
  individual?: Individual,
  company?: Company,
  userRoles: string[],
  deleted?: boolean,
  email: string;
}

export class Entity implements Entity {
  constructor(init?: EntityFormValues) {
    Object.assign(this, init);
  }
}

export class EntityFormValues {
  id: number = 0;
  entityType: number = 1;
  name: string = '';
  addressLine1: string = '';
  addressLine2: string = '';
  city: string = '';
  stateCode: string = '';
  zip: string = '';
  cellPhone: string = '';
  workPhone: string = '';
  individual?: Individual = new Individual();
  company?: Company = new Company();
  deleted?: boolean = false;
  email: string = '';

  constructor(entity?: EntityFormValues) {
    if (entity) {
      this.id = entity.id;
      this.entityType = entity.entityType;
      this.name = entity.name;
      this.addressLine1 = entity.addressLine1;
      this.addressLine2 = entity.addressLine2;
      this.city = entity.city;
      this.stateCode = entity.stateCode;
      this.zip = entity.zip;
      this.cellPhone = entity.cellPhone;
      this.workPhone = entity.workPhone;
      this.individual = entity.individual;
      this.company = entity.company;
      this.deleted = entity.deleted;
      this.email = entity.email;
    }
  }
}