import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { TabPane, Segment, Form, Button } from 'semantic-ui-react';
import DateInput from '../../../../app/common/form/DateInput';
import TextInput from '../../../../app/common/form/TextInput';
import { InputMasks } from '../../../../shared/InputMasks';
import { InvestmentFormValues } from '../../../../app/models/Investment/Investment';
import CurrencyInput from '../../../../app/common/form/CurrencyInput';
import { useStore } from '../../../../app/stores/store';
import EntityContext from '../../../../app/context/entityContext';
import SelectInput from '../../../../app/common/form/SelectInput';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { getFloatValue } from '../../../../shared/utils';

interface Props {
    investmentId: number;
    continueNextTab: () => void;
}

function AssetDetails({ investmentId, continueNextTab }: Props) {
    const emptyOption = { key: '' };
    const [propertyTypeOptions, setPropertyTypeOptions] = useState([emptyOption]);
    const { commonStore, investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [investment, setInvestment] = useState(new InvestmentFormValues());
    const [loading, setLoading] = useState(true);
    const [continueButtonCliked, setContinueButtonCliked] = useState(false);

    useEffect(() => {
        commonStore.getInvestmentEnums().then(r => {
            setPropertyTypeOptions(r.propertyType);
        })

        investmentStore.getInvestmentById(entity.id, investmentId).then((r) => {
            const result = new InvestmentFormValues(r);
            setInvestment(result);
        })
            .finally(() => setLoading(false));
    }, [investmentId, setInvestment, entity, investmentStore, setLoading, commonStore, setPropertyTypeOptions]);

    const validationSchema = yup.object({
        propertyType: yup
            .number()
            .max(99999999, 'Property Type is required')
            .nullable(),
        numberOfUnits: yup
            .number()
            .nullable(),
        yearBuilt: yup
            .number()
            .min(1800, "Year Built must be higher than 1800")
            .max(3000, 'Invalid Year')
            .nullable(),
        yearRenovated: yup
            .number()
            .min(1800, "Year Renovated must be higher than 1800")
            .max(3000, 'Invalid Year')
            .nullable(),
        acquisitionDate: yup
            .date()
            .default(() => new Date())
            .typeError("Invalid Date")
            .nullable(),
        purchasePrice: yup
            .number()
            .required("Acquisition Price is required")
            .min(0, 'Acquisition Price cannot be negative'),
        minimumInvestment: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Minimum Investment is required')
            .min(0, 'Minimum Investment cannot be negative'),
        totalRaiseAmount: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Total Raise Amount is required')
            .positive('Total Raise Amount must be greater than 0'),
        valueAtAcquisition: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Value at Acquisition is required')
            .positive('Total Raise Amount must be greater than 0'),
    });

    const handleFormSubmit = async (values: any, setSubmitting: any) => {
        if (values.acquisitionDate !== "") values.acquisitionDate = new Date(values.acquisitionDate);
        investmentStore.saveInvestment(new InvestmentFormValues(values))
            .then(r => {
                toast.success("Success!", { theme: "colored" });
                if (continueButtonCliked) continueNextTab();
            })
            .catch(err => {
                toast.error("There was an issue saving the investment.", { theme: "colored" });
            })
            .finally(() => setSubmitting(false));
    }
    return (
        <TabPane loading={loading}>
            <Segment clearing>
                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={investment}
                    onSubmit={(values, { setSubmitting }) => {
                        handleFormSubmit(values, setSubmitting);
                    }}
                >
                    {({ handleSubmit, isSubmitting }) => (
                        <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>
                            <Form.Group widths='equal'>
                                <SelectInput options={propertyTypeOptions} placeholder='Property Type' name='propertyTypeId' />
                                <TextInput placeholder='Property Class' name='propertyClass' maxLength={100} />
                                <CurrencyInput placeholder='Number of Units' name='numberOfUnits' allowNegative={false} maxLength={8} />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <CurrencyInput placeholder='Year Built' name='yearBuilt' allowNegative={false} maxLength={4} />
                                <CurrencyInput placeholder='Year Renovated' name='yearRenovated' allowNegative={false} maxLength={4} />
                                <DateInput placeholder='Acquisition Date' name='acquisitionDate' mask={InputMasks.date} />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <CurrencyInput placeholder='Acquisition Price' name='purchasePrice' thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} showRequired />
                                <CurrencyInput placeholder='Value at Acquisition' name='valueAtAcquisition' thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} showRequired />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <CurrencyInput placeholder='Minimum Investment' name='minimumInvestment' thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} showRequired />
                                <CurrencyInput placeholder='Total Raise Amount' name='totalRaiseAmount' thousandSeparator=',' decimalScale={2} allowNegative={false} maxLength={16} showRequired />
                            </Form.Group>
                            <br />
                            <Button disabled={isSubmitting} loading={isSubmitting} floated='right' color='green' type='submit' content='Continue' onClick={() => setContinueButtonCliked(true)} />
                            <Button disabled={isSubmitting} loading={isSubmitting} floated='right' primary type='submit' content='Save' />
                        </Form>
                    )}
                </Formik>
            </Segment>
        </TabPane>
    )
}

export default AssetDetails