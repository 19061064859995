import React from 'react';
import { Header } from 'semantic-ui-react';

interface Props {
    progress: number;
    message: string;
    color?: string;
    bgColor?: string;
    fontColor?: string;
    title?: string;
}

const CBProgressBar = ({
    progress = 0,
    message = '',
    color = "#21ba45",
    bgColor = "#e0e0e0",
    fontColor = "#ffffff",
    title
}: Props) => {
    const normalizedProgress = Math.min(Math.max(progress, 0), 100);

    const renderMessage = () => {
        if (normalizedProgress > 15) {
            return <div className="progress-bar-message">{message}</div>;
        } else {
            return <div
                className="progress-bar-message"
                style={{
                    left: `${normalizedProgress + 20}%`,
                    transform: `translateX(-${normalizedProgress}%)`,
                }}>
                {message}
            </div>;
        }
    };

    return <>
        {title &&
            <Header>{title}</Header>
        }
        <div className="progress-bar" style={{ backgroundColor: bgColor }}>
            <div className="progress-bar-filler"
                style={{
                    width: `${normalizedProgress}%`,
                    backgroundColor: color,
                    color: fontColor,
                }}>
                {renderMessage()}
            </div>
        </div>
    </>;
};

export default CBProgressBar;
