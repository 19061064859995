import React from 'react'
import { TabPane } from 'semantic-ui-react'
import { Investment, ParticipationType } from '../../../app/models/Investment/Investment';
import ShareholderDistributionGrid from './ShareholderDistributionGrid';
import SponsorDistributionGrid from '../Manage/Distributions/SponsorDistributionGrid';

interface Props {
    investment: Investment;
}

function PublicDistributionInfo({ investment }: Props) {
    return (
        <TabPane>
            {investment.entityParticipation === ParticipationType.shareholder ?
                <ShareholderDistributionGrid investment={investment} />
                :
                <SponsorDistributionGrid investment={investment} showIssueDistributionButton={false} />
            }
        </TabPane>
    )
}

export default PublicDistributionInfo