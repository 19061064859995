import React, { useContext, useEffect, useState } from 'react'
import { Button, Header, Segment, Tab } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import EntityContext from '../../app/context/entityContext';
import { InvestmentFormValues } from '../../app/models/Investment/Investment';
import Shares from './Shares/Shares';
import Financial from './Financial/Financial';
import InvestmentDocuments from './Documents/InvestmentDocuments';
import InvestmentDetail from './Details/InvestmentDetail';
import Offerings from './Offerings/Offerings';
import { getLastSegmentOfRoute } from '../../shared/utils';
import PublicDistributionInfo from './Distributions/PublicDistributionInfo';

function Investment() {
    const { id } = useParams();
    const { investmentStore } = useStore();
    const { entity } = useContext(EntityContext);
    const [currentInvestment, setCurrentInvestment] = useState<InvestmentFormValues>(new InvestmentFormValues());
    const [loading, setLoading] = useState(true);
    const hasOfferingsInRoute = getLastSegmentOfRoute() === "offerings";
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (id) {
            investmentStore.getInvestmentWithFinancial(entity.id, Number(id)).then((r) => {
                setCurrentInvestment(new InvestmentFormValues(r));
                if (hasOfferingsInRoute) setActiveIndex(5);
            })
                .finally(() => setLoading(false));
        }
    }, [investmentStore, id, entity, setLoading, hasOfferingsInRoute])

    var panes = [
        {
            menuItem: { key: 'Details', content: 'Details' },
            render: () => <InvestmentDetail investment={currentInvestment} />
        },
        {
            menuItem: { key: 'Financial', content: 'Financial' },
            render: () => <Financial investment={currentInvestment} />
        },
        {
            menuItem: { key: 'Shares', content: 'Shares' },
            render: () => <Shares investment={currentInvestment} />
        },
        {
            menuItem: { key: 'Distributions', content: 'Distributions' },
            render: () => <PublicDistributionInfo investment={currentInvestment} />
        },
        {
            menuItem: { key: 'Documents', content: 'Documents' },
            render: () => <InvestmentDocuments investment={currentInvestment} />
        },
        {
            menuItem: { key: 'Current Offerings', content: 'Current Offerings' },
            render: () => <Offerings investment={currentInvestment} />
        }
    ];

    if (currentInvestment.currentFinancialInfo === null) {
        panes = panes.filter(x => x.menuItem.key !== "Financial");
    }

    return (
        <Segment basic loading={loading}>
            <Header as='h3' color='blue'>
                {currentInvestment.name}
                <Button floated='right' content='Back to Investments' as={Link} to='/investments' />
            </Header>
            <Tab panes={panes} defaultActiveIndex={activeIndex} key={activeIndex} />
        </Segment>
    )
}

export default Investment