import { Grid, GridColumn, Header, Icon, Segment } from "semantic-ui-react"
import { InvestmentSummaryView } from "../../app/models/GpLanding/InvestmentSummaryView"
import { useContext, useEffect, useState } from "react";
import EntityContext from "../../app/context/entityContext";
import InvestmentSummaryCard from "../../shared/InvestmentSummaryCard";
import { useStore } from "../../app/stores/store";
import React from "react";


function DiscoverInvestments() {
    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(true);
    const { marketPlaceStore } = useStore();
    const [investments, setInvestments] = useState<InvestmentSummaryView[]>();
    useEffect(() => {
        setLoading(true);
        marketPlaceStore.getConnectedInvestments(entity.id)
            .then(r => setInvestments(r))
            .finally(() => setLoading(false));
    }, [entity, marketPlaceStore, setInvestments, setLoading])

    return (
        <Segment loading={loading}>
            <Header as='h2' image>
                <Icon name='building'></Icon>
                <Header.Content>Discover Investments</Header.Content>
            </Header>
            {investments && investments.length > 0 ?
                <Grid columns={2}>
                    {!loading && investments.map((investment, i) => {
                        return (
                            <GridColumn key={i}>
                                <InvestmentSummaryCard investment={investment} isConnected={true}></InvestmentSummaryCard>
                            </GridColumn>
                        )
                    })}

                </Grid>
                :
                <Segment placeholder>
                    <Header as='h2' icon>
                        <Icon name='building outline' size='massive' />
                        No qualified investments are available.
                    </Header>
                </Segment>
            }
        </Segment>
    )

}

export default DiscoverInvestments