export interface NewMonthlyInvestmentFinancial {
  interestType: number;
  loanToValue: number | string;
  interestRate: number | string;
  term: number | string;
  interestOnly: number | string;
  interestYears: number | string;
  lender: string;
  ownedSince: string;
  debtCoverage: number | string;
  annualDebtService: number;
  totalLoan: number;
  id: number;
  investmentId: number;
  loanStartDate: string;
}

export class NewMonthlyInvestmentFinancial implements NewMonthlyInvestmentFinancial {
  constructor(init?: NewMonthlyInvestmentFinancial) {
    Object.assign(this, init);
  }
}

export class NewMonthlyInvestmentFinancialFormValues {
  interestType: number = 0;
  loanToValue: number | string = '';
  interestRate: number | string = '';
  term: number | string = '';
  interestOnly: number | string = '';
  interestYears: number | string = '';
  lender: string = '';
  ownedSince: string = '';
  debtCoverage: number | string = '';
  annualDebtService: number | string = '';
  totalLoan: number | string = '';
  id: number = 0;
  investmentId: number = 0;
  loanStartDate: string = '';

  constructor(financial?: NewMonthlyInvestmentFinancialFormValues) {
    if (financial) {
      this.id = financial.id;
      this.investmentId = financial.investmentId;
      this.annualDebtService = financial.annualDebtService;
      this.debtCoverage = financial.debtCoverage;
      this.interestRate = financial.interestRate;
      this.interestType = financial.interestType;
      this.lender = financial.lender;
      this.loanToValue = financial.loanToValue;
      this.ownedSince = financial.ownedSince;
      this.term = financial.term;
      this.totalLoan = financial.totalLoan;
      this.interestOnly = financial.interestOnly;
      this.interestYears = financial.interestYears;
      this.loanStartDate = financial.loanStartDate;
    }
  }
}