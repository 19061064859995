import React, { useContext, useState } from 'react'
import { Dropdown, Grid, TabPane } from 'semantic-ui-react'
import { Investment } from '../../../app/models/Investment/Investment';
import { useStore } from '../../../app/stores/store';
import EntityContext from '../../../app/context/entityContext';
import FinancialGraph from './Graph/FinancialGraph';
import { observer } from 'mobx-react-lite';
import FinancialsForm from '../Manage/Financials/FinancialsForm';

interface Props {
    investment: Investment;
}

function Financial({ investment }: Props) {
    const [historicDates] = useState(investment.historicFinancialInfo
        .map(s => ({ key: s.id, value: s.id, text: s.asofDate }))
        .filter((item, index, self) =>
            index === self.findIndex((t) => t.key === item.key && t.text === item.text)
        ));
    const [graphData, setGraphData] = useState([...investment.historicFinancialInfo]);

    const [loading, setLoading] = useState(false);
    const { investmentStore } = useStore();

    const { entity } = useContext(EntityContext);

    const [monthlyFinancialId, setMonthlyFinancialId] = useState(investment.currentFinancialInfo.id);

    const getHistoricFinancialInfo = (id: number) => {
        setLoading(true);
        investmentStore.getMonthlyInvestmentFinancial(entity.id, investment.id, id)
            .then((r) => {
                restrictGraphData(id);
                setMonthlyFinancialId(id);
            })
            .finally(() => setLoading(false));
    }

    function restrictGraphData(id: number) {
        const value = [...investment.historicFinancialInfo];
        setGraphData(value.splice(value.findIndex((item) => item.id === id)));
    }

    return (
        <TabPane loading={loading}>

            <FinancialGraph data={graphData} />

            <Grid columns={3} >
                <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                        As Of: {' '}
                        <Dropdown compact selection
                            options={historicDates} defaultValue={investment.filteredFinancialInfo.id}
                            onChange={(e, d) => {
                                getHistoricFinancialInfo(Number(d.value));
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <FinancialsForm investment={investment} editFinancialId={monthlyFinancialId} allowEdit={false}></FinancialsForm>

        </TabPane>
    )
}

export default observer(Financial)